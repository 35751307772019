<template>
</template>

<script>

export default {
    name: 'Login',
    
    mounted() {
        localStorage.clear();
    }

}
</script>

<style>

</style>