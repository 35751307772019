<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon large v-show="!checkFilters" @click="clearFilter"
        >mdi-filter-remove-outline</v-icon
      >

      <v-icon large v-bind="attrs" v-on="on">mdi-filter</v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Filtra per:</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-if="$store.state.livello > 0">
              <v-text-field
                label="Codice filiale"
                v-model="codice_filiale"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Codice inventario"
                v-model="codice_inventario"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Aperto da"
                v-model="aperto_da"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Chiuso da"
                v-model="chiuso_da"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="8">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                ref="menu"
                :return-value.sync="dates2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Dalla data - alla data"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click="dateRangeText = ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates2"
                  range
                  :min="minDate"
                  :max="current_date"
                  locale="it-IT"
                  color="primary"
                  ><v-btn
                    tile
                    color="primary"
                    class="ml-auto"
                    small
                    @click="confirmDates"
                  >
                    OK
                  </v-btn></v-date-picker
                >
              </v-menu>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select
                :items="status"
                item-text="text"
                item-value="value"
                label="Stato"
                v-model="stato"
                multiple
                chips
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="clearFilter">
          Cancella tutto
        </v-btn>
        <v-btn color="secondary" text @click="dialog = false">
          Chiudi
        </v-btn>
        <v-btn color="primary" text @click="passParameters">
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
var dayjs = require("dayjs");

export default {
  name: "InventoryFilter",

  data() {
    return {
      dialog: false,
      codice_filiale: "",
      codice_inventario: "",
      aperto_da: "",
      chiuso_da: "",
      stato: "",
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dates: [],
      dates2: [],
      status: [
        { text: "Aperto", value: "0" },
        { text: "Chiuso senza anomalie e senza annotazioni", value: "1" },
        { text: "Chiuso con annotazioni", value: "2" },
        { text: "Chiuso con anomalie", value: "3" },
      ],
      hover: false,
      filtersObj: {
        codiceFiliale: "",
        codiceInventario: "",
        apertoDa: "",
        chiusoDa: "",
        dates: [],
        stato: "",
      },
      dateRangeText: "",
    };
  },
  mounted() {
    this.filtersObj = this.$store.state.filtersObj;
    this.codice_filiale = this.filtersObj.codiceFiliale;
    this.codice_inventario = this.filtersObj.codiceInventario;
    this.aperto_da = this.filtersObj.apertoDa;
    this.chiuso_da = this.filtersObj.chiusoDa;
    if (this.filtersObj.dates.length != 0) {
      this.dates2[0] = dayjs(this.filtersObj.dates[0]).format("YYYY-DD-MM");
      this.dates2[1] = dayjs(this.filtersObj.dates[1]).format("YYYY-DD-MM");
      this.confirmDates();
    }
    this.stato = this.filtersObj.stato;
  },
  computed: {
    checkFilters() {
      const isEmpty = Object.values(this.filtersObj).every(
        (x) => x === null || x === "" || x.length === 0
      );

      if (isEmpty) {
        return true;
      } else {
        return false;
      }
    },
    minDate() {
      if (this.dates2.length == 1) {
        return new Date(this.dates2[0]).toISOString().substr(0, 10);
      }
    },
  },
  methods: {
    confirmDates() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.save(this.dates2);
      }
      this.dates = [];
      this.dateRangeText = "";
      this.dates.push(this.dates2[0]);
      if (this.dates2.length == 1) {
        this.dates.push(this.current_date);
        this.dates2.push(this.current_date);
      } else {
        this.dates.push(this.dates2[1]);
      }
      this.dates[0] = dayjs(this.dates[0]).format("DD/MM/YYYY");
      this.dates[1] = dayjs(this.dates[1]).format("DD/MM/YYYY");
      this.dateRangeText = this.dates.join(" ~ ");
    },
    clearFilter() {
      this.codice_filiale = "";
      this.codice_inventario = "";
      this.aperto_da = "";
      this.chiuso_da = "";
      this.dates = [];
      this.dates2 = [];
      this.stato = "";
      this.dateRangeText = "";

      this.filtersObj = {
        codiceFiliale: this.codice_filiale,
        codiceInventario: this.codice_inventario,
        apertoDa: this.aperto_da,
        chiusoDa: this.chiuso_da,
        dates: this.dates,
        stato: this.stato,
      };
      this.$emit("filter-inventory", this.filtersObj);
    },
    passParameters() {
      this.dialog = false;
      this.filtersObj = {
        codiceFiliale: this.codice_filiale,
        codiceInventario: this.codice_inventario,
        apertoDa: this.aperto_da,
        chiusoDa: this.chiuso_da,
        dates: this.dates,
        stato: this.stato,
      };
      this.$emit("filter-inventory", this.filtersObj);
    },
  },
};
</script>
<style>
</style>