import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Inventory from '../views/Inventory.vue'
import Utenti from '../views/Utenti.vue'
import StoricoPalmari from '../views/StoricoPalmari.vue'
import GestionePalmari from '../views/GestionePalmari.vue'
import InventoryDetails from '../views/InventoryDetails.vue'
import InventorySummary from '../views/InventorySummary.vue'
import GestioneAree from '../views/GestioneAree.vue'
import ImportPalmari from '../views/ImportPalmari.vue'
import PageError from '../views/PageError.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login/:jwt',
    name: 'Login',
    component: Login,
  },
  {
    path: '/utenti',
    name: 'Utenti',
    component: Utenti,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/storico-palmari',
    name: 'StoricoPalmari',
    component: StoricoPalmari,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/gestione-palmari',
    name: 'GestionePalmari',
    component: GestionePalmari,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/inventory-details/:id',
    name: 'InventoryDetails',
    component: InventoryDetails,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/inventory-summary/:id',
    name: 'InventorySummary',
    component: InventorySummary,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/gestione-aree',
    name: 'GestioneAree',
    component: GestioneAree,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/import-palmari',
    name: 'ImportPalmari',
    component: ImportPalmari,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '*',
    name: 'Error',
    component: PageError,
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'Login',
      })
    } else {
      next()
    }
  }
  else
    if (to.matched.some(record => record.meta.requiresVisitor)) {
      if (store.getters.loggedIn) {
        next({
          name: 'Inventory',
        })
      } else {
        next()
      }
    } else {
      next()
    }
})

export default router
