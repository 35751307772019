<template>
  <v-container>
    <v-row>
      <v-col>
        <v-sheet
          min-height="calc(100vh - 88px)"
          rounded="lg"
          class="px-5 pt-5 position-txt"
        >
          <h3>Gestione utenti</h3>

          <v-progress-circular
            class="new-inv"
            indeterminate
            size="64"
            v-if="usersLoaded == false"
          ></v-progress-circular>

          <!-- ALERT -->
          <v-dialog v-model="alert" max-width="290">
            <v-card>
              <v-card-title class="text-h5 alert">
                {{ message }}
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="alert = false">
                  Chiudi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- TABLE -->
          <v-data-table
            :headers="headers"
            :items="users"
            sort-by="username"
            :items-per-page="itemsPerPage"
            :search="search"
            :custom-filter="filterAll"
            no-results-text="Nessun risultato"
            fixed-header
            :footer-props="{
              'items-per-page-options': [15, 25, 50, -1],
              'items-per-page-text': 'Elementi per pagina',
              'items-per-page-all-text': 'Tutti',
            }"
            class="my-5"
            height="calc(100vh - 280px)"
            @current-items="getData"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  v-model="search"
                  label="Cerca"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                ></v-text-field>

                <!-- FILTER -->
                <v-dialog v-model="filterDialog" persistent max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon large v-show="!checkFilters" @click="clearFilter"
                      >mdi-filter-remove-outline</v-icon
                    >

                    <v-icon large v-bind="attrs" v-on="on">mdi-filter</v-icon>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Filtra per:</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              class="mt-5 mr-5"
                              v-model="filtersObj.cd_filiale"
                              :items="filialiCodes"
                              :search-input.sync="filterSearch"
                              label="Codice filiale/area"
                              chips
                              deletable-chips
                              clearable
                              no-data-text="Nessun risultato"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="filtersObj.nm_filiale"
                              label="Nome filiale/area"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="filtersObj.username"
                              label="Username"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              :items="level"
                              item-text="text"
                              item-value="value"
                              label="Livello"
                              v-model="filtersObj.livello"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            class="d-flex flex-column justify-center"
                          >
                            <span>Tracking</span>
                            <v-checkbox
                              color="primary"
                              value="1"
                              v-model="filtersObj.tracking[0]"
                              hide-details
                              class="mt-1"
                            >
                              <template v-slot:label>
                                <v-icon color="green">mdi-check-bold</v-icon>
                              </template>
                            </v-checkbox>
                            <v-checkbox
                              color="primary"
                              value="0"
                              v-model="filtersObj.tracking[1]"
                              hide-details
                              class="mt-1"
                            >
                              <template v-slot:label>
                                <v-icon color="red">mdi-close-thick</v-icon>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            class="d-flex flex-column justify-center"
                          >
                            <span>Inventario</span>
                            <v-checkbox
                              color="primary"
                              value="1"
                              v-model="filtersObj.inventario[0]"
                              hide-details
                              class="mt-1"
                            >
                              <template v-slot:label>
                                <v-icon color="green">mdi-check-bold</v-icon>
                              </template>
                            </v-checkbox>
                            <v-checkbox
                              color="primary"
                              value="0"
                              v-model="filtersObj.inventario[1]"
                              hide-details
                              class="mt-1"
                            >
                              <template v-slot:label>
                                <v-icon color="red">mdi-close-thick</v-icon>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            class="d-flex flex-column justify-center"
                          >
                            <span>Chat</span>
                            <v-checkbox
                              color="primary"
                              value="1"
                              v-model="filtersObj.chat[0]"
                              hide-details
                              class="mt-1"
                            >
                              <template v-slot:label>
                                <v-icon color="green">mdi-check-bold</v-icon>
                              </template>
                            </v-checkbox>
                            <v-checkbox
                              color="primary"
                              value="0"
                              v-model="filtersObj.chat[1]"
                              hide-details
                              class="mt-1"
                            >
                              <template v-slot:label>
                                <v-icon color="red">mdi-close-thick</v-icon>
                              </template>
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" text @click="clearFilter">
                        Cancella tutto
                      </v-btn>
                      <v-btn
                        color="secondary"
                        text
                        @click="filterDialog = false"
                      >
                        Chiudi
                      </v-btn>
                      <v-btn color="primary" text @click="filterDialog = false">
                        Conferma
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <!-- NEW/MODIFY DIALOG -->
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn fab small color="secondary" v-bind="attrs" v-on="on">
                      <v-icon color="white"> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-select
                                :items="level"
                                item-text="text"
                                item-value="value"
                                label="Livello"
                                v-model="editedUser.livello"
                                @change="
                                  () => {
                                    editedUser.cd_filiale = '';
                                    editedUser.nm_filiale = '';
                                    editedUser.filiali_aggiuntive = [];
                                  }
                                "
                              ></v-select>
                            </v-col>
                            <v-col cols="12" v-if="editedUser.livello == '1'">
                              <v-autocomplete
                                v-model="editedUser.cd_filiale"
                                :items="areas"
                                :search-input.sync="search2"
                                label="Codice area"
                                chips
                                deletable-chips
                                clearable
                                @change="getName(editedUser.cd_filiale)"
                                no-data-text="Nessun risultato"
                                :rules="nameRules"
                              >
                              </v-autocomplete>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              v-if="editedUser.livello == '1'"
                            >
                              <v-text-field
                                v-model="editedUser.nm_filiale"
                                label="Area"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              v-if="
                                editedUser.livello == '0' ||
                                editedUser.livello == '3'
                              "
                            >
                              <v-autocomplete
                                v-model="editedUser.cd_filiale"
                                :items="filiali"
                                :search-input.sync="search1"
                                label="Codice filiale"
                                chips
                                deletable-chips
                                clearable
                                @change="getName(editedUser.cd_filiale)"
                                no-data-text="Nessun risultato"
                                :rules="nameRules"
                              >
                              </v-autocomplete>
                            </v-col>
                            <v-col
                              cols="12"
                              v-if="
                                editedUser.livello == '0' ||
                                editedUser.livello == '3'
                              "
                            >
                              <v-text-field
                                v-model="editedUser.nm_filiale"
                                label="Filiale"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="editedUser.livello == '3'">
                              <v-autocomplete
                                v-model="editedUser.filiali_aggiuntive"
                                :items="filialiDaAgg"
                                item-value="codice"
                                item-text="text"
                                label="Filiali aggiuntive"
                                chips
                                deletable-chips
                                clearable
                                no-data-text="Nessun risultato"
                                multiple
                              >
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                v-model="editedUser.username"
                                label="Username"
                                :rules="nameRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                v-model="editedUser.password1"
                                :label="
                                  editedIndex === -1
                                    ? 'Password'
                                    : 'Nuova password'
                                "
                                :rules="passRule1"
                                type="password"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                v-model="editedUser.password2"
                                :label="
                                  editedIndex === -1
                                    ? 'Ripeti password'
                                    : 'Ripeti nuova password'
                                "
                                :rules="passRule2"
                                type="password"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-checkbox
                                v-model="editedUser.accesso_tracking"
                                label="Tracking"
                                color="primary"
                                value="1"
                                hide-details
                                @change="editedUser.accesso_chat = editedUser.accesso_tracking ? editedUser.accesso_chat : false"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-checkbox
                                v-model="editedUser.accesso_inventario"
                                label="Inventario"
                                color="primary"
                                value="1"
                                hide-details
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-checkbox
                                v-model="editedUser.accesso_chat"
                                label="Chat"
                                color="primary"
                                value="1"
                                hide-details
                                :disabled="!editedUser.accesso_tracking"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" text @click="close">
                        Chiudi
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="save"
                        :disabled="!valid"
                      >
                        Salva
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- DELETE DIALOG -->
                <v-dialog v-model="dialogDelete" max-width="550px">
                  <v-card>
                    <v-card-title
                      class="text-h5 justify-center text-center delete"
                      >Sei sicuro di voler cancellare l'utente "{{
                        editedUser.username
                      }}"?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" text @click="closeDelete"
                        >Chiudi</v-btn
                      >
                      <v-btn color="primary" text @click="deleteUserConfirm"
                        >Conferma</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <!-- TABLE COLUMNS -->
            <template v-slot:[`item.filiali_aggiuntive`]="{ item }">
              <v-tooltip bottom v-if="item.filiali_aggiuntive && item.filiali_aggiuntive.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" color="rgba(0, 0, 0, 0.12)">
                    {{ item.filiali_aggiuntive.length }}
                  </v-chip>
                </template>
                <!-- Filiale1 (001), Filiale2 (002), ...-->
                <span>{{ getFilialiAgg(item.filiali_aggiuntive) }}</span>
              </v-tooltip>

              <!-- Non mostro il tootlip se non ci sono filiali associate-->
              <!-- <v-chip v-else>
                {{ item.filiali_aggiuntive }}
              </v-chip> -->
            </template>

            <template v-slot:[`item.data_login`]="{ item }">
              {{ formatDate(item.data_login) }}
            </template>

            <template v-slot:[`item.livello`]="{ item }">
              {{ getLevel(item.livello) }}
            </template>
            <template v-slot:[`item.accesso_tracking`]="{ item }">
              <v-icon color="green" v-if="item.accesso_tracking >= 1"
                >mdi-check-bold</v-icon
              >
              <v-icon color="red" v-if="item.accesso_tracking == 0"
                >mdi-close-thick</v-icon
              >
            </template>
            <template v-slot:[`item.accesso_inventario`]="{ item }">
              <v-icon color="green" v-if="item.accesso_inventario >= 1"
                >mdi-check-bold</v-icon
              >
              <v-icon color="red" v-if="item.accesso_inventario == 0"
                >mdi-close-thick</v-icon
              >
            </template>
            <template v-slot:[`item.accesso_chat`]="{ item }">
              <v-icon color="green" v-if="item.accesso_chat >= 1"
                >mdi-check-bold</v-icon
              >
              <v-icon color="red" v-if="item.accesso_chat == 0"
                >mdi-close-thick</v-icon
              >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editUser(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteUser(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="getUsers"> Reset </v-btn>
            </template>
            <template
              v-slot:[`footer.prepend`]
              v-if="$store.state.livello == '2'"
            >
              <v-btn
                @click="downloadCSV()"
                text
                class="ml-4 px-2"
                color="grey darken-3"
              >
                Esporta
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <!-- EXPORT DIALOG -->
          <v-dialog v-model="dialogCSV" max-width="500px">
            <v-card>
              <v-card-title class="headline pt-4">Download CSV</v-card-title>
              <v-card-text>
                <div class="mt-4">
                  <p style="display: inline">File .csv pronto!</p>
                  <a
                    :download="'Utenti-' + dateNow + '.csv'"
                    :href="fileCSV"
                    style="text-decoration: none; display: inline"
                    class="ml-4"
                  >
                    <v-btn @click="dialogCSV = false" color="primary">
                      Download
                      <v-icon right> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogCSV = false"
                  >Chiudi</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
var dayjs = require("dayjs");

export default {
  name: "Utenti",

  data() {
    return {
      usersLoaded: false,
      users: [],
      itemsPerPage: 15,
      alert: false,
      message: "",
      dialog: false,
      dialogDelete: false,
      filterDialog: false,
      filialiAll: [],
      filiali: [],
      areas: [],
      filialiCodes: [],
      search: "",
      search1: "",
      search2: "",
      valid: true,
      dialogCSV: false,
      dateNow: "",
      fileCSV: null,
      headers: [
        {
          text: "Codice filiale",
          align: "start",
          value: "cd_filiale",
          width: "10%",
          filter: (value) => {
            if (!this.filtersObj.cd_filiale) return true;
            return value.includes(this.filtersObj.cd_filiale);
          },
        },
        {
          text: "Filiale",
          value: "nm_filiale",
          width: "10%",
          filter: (value) => {
            if (!this.filtersObj.nm_filiale) return true;
            if (value != null) {
              return value
                .toLowerCase()
                .includes(this.filtersObj.nm_filiale.toLowerCase());
            }
          },
        },
        {
          text: "Filiali aggiuntive",
          value: "filiali_aggiuntive",
          width: "10%",
        },
        {
          text: "Username",
          value: "username",
          width: "10%",
          filter: (value) => {
            if (!this.filtersObj.username) return true;
            return value
              .toLowerCase()
              .includes(this.filtersObj.username.toLowerCase());
          },
        },
        {
          text: "Ultimo login",
          value: "data_login",
          width: "10%",
        },
        {
          text: "Livello",
          value: "livello",
          width: "10%",
          filter: (value) => {
            if (!this.filtersObj.livello) return true;
            return value === this.filtersObj.livello;
          },
        },
        {
          text: "Tracking",
          value: "accesso_tracking",
          width: "10%",
          filter: (value) => {
            if (
              this.filtersObj.tracking[0] == null &&
              this.filtersObj.tracking[1] == null
            )
              return true;
            return this.filtersObj.tracking.includes(value);
          },
        },
        {
          text: "Inventario",
          value: "accesso_inventario",
          width: "10%",
          filter: (value) => {
            if (
              this.filtersObj.inventario[0] == null &&
              this.filtersObj.inventario[1] == null
            )
              return true;
            return this.filtersObj.inventario.includes(value);
          },
        },
        {
          text: "Chat",
          value: "accesso_chat",
          width: "10%",
          filter: (value) => {
            if (
              this.filtersObj.chat[0] == null &&
              this.filtersObj.chat[1] == null
            )
              return true;
            return this.filtersObj.chat.includes(value);
          },
        },
        { text: "Azioni", value: "actions", sortable: false, width: "10%" },
      ],
      level: [
        { text: "Utente", value: "0" },
        { text: "Utente customer", value: "3" },
        { text: "Capo area", value: "1" },
        { text: "Admin", value: "2" },
      ],
      filialiDaAgg: [],
      editedIndex: -1,
      editedUser: {
        livello: "",
        cd_filiale: "",
        nm_filiale: "",
        username: "",
        password1: "",
        password2: "",
        accesso_tracking: 0,
        accesso_inventario: 0,
        accesso_chat: 0,
        filiali_aggiuntive: [],
      },
      defaultUser: {
        livello: "",
        cd_filiale: "",
        nm_filiale: "",
        username: "",
        password1: "",
        password2: "",
        accesso_tracking: 0,
        accesso_inventario: 0,
        accesso_chat: 0,
        filiali_aggiuntive: [],
      },
      nameRules: [(v) => !!v || "Campo obbligatorio"],
      passRule1: [(v) => !v || v.length >= 6 || "Min 6 caratteri"],
      passRule2: [
        (v) =>
          !v ||
          v == this.editedUser.password1 ||
          "Le due password non corrispondono",
      ],
      filterSearch: "",
      filtersObj: {
        cd_filiale: "",
        nm_filiale: "",
        username: "",
        password: [null, null],
        livello: "",
        tracking: [null, null],
        inventario: [null, null],
        chat: [null, null],
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuovo utente" : "Modifica utente";
    },
    checkFilters() {
      const isEmpty = Object.values(this.filtersObj).every(
        (x) =>
          x === null ||
          x === "" ||
          x.length === 0 ||
          (x[0] === null && x[1] === null)
      );

      if (isEmpty) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getUsers();

    axios
      .get(`${this.$store.state.url}getFiliali.php`)
      .then((response) => {
        this.filialiAll = [...response.data];
        this.areas = this.filialiAll
          .filter((user) => user.tipologia == 1)
          .map((user) => user.codice);
        this.filiali = this.filialiAll
          .filter((user) => /^\d+$/.test(user.codice))
          .map((user) => user.codice);
        this.filialiCodes = [...this.filiali, ...this.areas];
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getUsers() {
      axios
        .get(`${this.$store.state.url}getUtenti.php`)
        .then((response) => {
          this.users = [...response.data];
          this.usersLoaded = true;
        })
        .catch((error) => {
          console.log(error);
          this.usersLoaded = true;
        });
    },
    getName(codice) {
      this.editedUser.nm_filiale = "";
      this.filialiAll.forEach((filiale) => {
        if (filiale.codice == codice && codice != "") {
          this.editedUser.nm_filiale = filiale.nm_filiale;
        }
      });
      var i = this.editedUser.filiali_aggiuntive.findIndex(
        (fil) => fil == codice
      );
      if (i > -1) {
        this.editedUser.filiali_aggiuntive.splice(i, 1);
      }
      this.filialiDaAgg = this.filialiAll
        .filter((user) => /^\d+$/.test(user.codice) && user.codice != codice)
        .map((user) => {
          user["text"] = `${user.codice} - ${user.nm_filiale}`;
          return user;
        });
    },
    scrollTable(el) {
      el.$el.getElementsByClassName("v-data-table__wrapper")[0].scrollTop = 0;
    },
    formatDate(date) {
      if (date != "" && date != null) {
        return dayjs(date).format("DD/MM/YYYY HH:mm");
      }
    },
    getLevel(level) {
      if (level == "0") {
        return "Utente";
      } else if (level == "1") {
        return "Capo area";
      } else if (level == "2") {
        return "Admin";
      } else {
        return "Utente customer";
      }
    },
    editUser(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.filialiDaAgg = this.filialiAll
        .filter(
          (user) =>
            /^\d+$/.test(user.codice) &&
            user.codice != this.editedUser.cd_filiale
        )
        .map((user) => {
          user["text"] = `${user.codice} - ${user.nm_filiale}`;
          return user;
        });
      this.dialog = true;
    },
    deleteUser(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteUserConfirm() {
      var username = this.editedUser.username;
      axios
        .delete(`${this.$store.state.url}deleteUtenti.php`, {
          data: {
            jwt: this.$store.state.token,
            ID_LOGIN: this.editedUser.ID_LOGIN,
          },
        })
        .then((response) => {
          this.getUsers();
          this.$root.Snackbar.show({
            text: `L'utente "${username}" è stato cancellato`,
          });
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.alert = true;
        });

      this.closeDelete();
    },
    close() {
      this.dialog = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },
    save() {
      var username = this.editedUser.username;
      if (this.$refs.form.validate()) {
        if (this.editedUser.livello == "2") {
          this.editedUser.cd_filiale = "000";
        }
        var filiali = []
        if (this.editedUser.livello == "3") {
          filiali = [...this.editedUser.filiali_aggiuntive, this.editedUser.cd_filiale];
        } else {
          filiali = [this.editedUser.cd_filiale];
        }
        if (this.editedIndex > -1) {
          axios
            .put(`${this.$store.state.url}updateUtenti.php`, {
              jwt: this.$store.state.token,
              ID_LOGIN: this.editedUser.ID_LOGIN,
              livello: this.editedUser.livello,
              cd_filiale: this.editedUser.cd_filiale,
              username: this.editedUser.username,
              password: this.editedUser.password1,
              accesso_tracking: this.editedUser.accesso_tracking,
              accesso_inventario: this.editedUser.accesso_inventario,
              accesso_chat: this.editedUser.accesso_chat,
              filiali_aggiuntive: filiali,
            })
            .then((response) => {
              this.getUsers();
              this.$root.Snackbar.show({
                text: `L'utente "${username}" è stato modificato`,
              });
            })
            .catch((error) => {
              console.log(error);
              this.message = error.response.data.message;
              this.alert = true;
            });
        } else {
          axios
            .post(`${this.$store.state.url}insertUtenti.php`, {
              jwt: this.$store.state.token,
              livello: this.editedUser.livello,
              cd_filiale: this.editedUser.cd_filiale,
              username: this.editedUser.username,
              password: this.editedUser.password1,
              accesso_tracking: this.editedUser.accesso_tracking,
              accesso_inventario: this.editedUser.accesso_inventario,
              accesso_chat: this.editedUser.accesso_chat,
              filiali_aggiuntive: filiali,
            })
            .then((response) => {
              this.getUsers();
              this.$root.Snackbar.show({
                text: `L'utente "${username}" è stato creato`,
              });
            })
            .catch((error) => {
              this.message = error.response.data.message;
              this.alert = true;
            });
        }
        this.close();
      }
    },
    filterAll(valore, search, item) {
      var results = [];
      var columns = [];

      this.headers.forEach((element) => {
        columns.push(element.value);
      });

      Object.entries(item).forEach(([key, value]) => {
        if (key == "livello") {
          if (value == "0") {
            value = "Utente";
          } else if (value == "1") {
            value = "Capo area";
          } else if (value == "2") {
            value = "Admin";
          } else {
            value = "Utente customer";
          }
        }

        if (key == "data_login") {
          value = this.formatDate(value);
        }

        if (
          value != undefined &&
          value != null &&
          value != "" &&
          columns.includes(key) &&
          search != null &&
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
        ) {
          results.push("true");
        }
      });
      return results.includes("true");
    },
    clearFilter() {
      this.filtersObj = {
        cd_filiale: "",
        nm_filiale: "",
        username: "",
        password: [null, null],
        livelli: "",
        tracking: [null, null],
        inventario: [null, null],
        chat: [null, null],
      };
    },
    getData(e) {
      this.currentItems = e;
    },
    getFilialiAgg(filiali) {
      var filialiAgg = "";
      filiali.forEach((fil, i) => {
        this.filialiAll.forEach((el) => {
          if (el.codice == fil) {
            if (i == filiali.length - 1) {
              filialiAgg += `${el.nm_filiale} (${el.codice})`;
            } else {
              filialiAgg += `${el.nm_filiale} (${el.codice}), `;
            }
          }
        });
      });
      return filialiAgg;
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;

      this.currentItems.forEach((row) => {
        if (row["livello"] == "0") {
          row["livelloText"] = "Utente";
        } else if (row["livello"] == "1") {
          row["livelloText"] = "Capo area";
        } else if (row["livello"] == "2") {
          row["livelloText"] = "Admin";
        } else {
          row["livelloText"] = "Utente customer";
        }

        row["filialiText"] = "";
        if (row["filiali_aggiuntive"] && row["filiali_aggiuntive"].length > 0) {
          row["filialiText"] = this.getFilialiAgg(row["filiali_aggiuntive"]);
        }

        if (row["accesso_tracking"] >= 1) {
          row["trackingText"] = "Si";
        } else {
          row["trackingText"] = "No";
        }

        if (row["accesso_inventario"] >= 1) {
          row["inventarioText"] = "Si";
        } else {
          row["inventarioText"] = "No";
        }

        if (row["accesso_chat"] >= 1) {
          row["chatText"] = "Si";
        } else {
          row["chatText"] = "No";
        }
      });
      this.$nextTick(() => {
        csv += `"Codice filiale";"Filiale";"Filiali aggiuntive";"Username";"Ultimo login";"Livello";"Abiliato al tracking";"Abilitato all'inventario";"Abilitato alla chat"\n`;
        this.currentItems.forEach((row) => {
          csv += `"=""${row["cd_filiale"]}""";"${
            row["nm_filiale"] == null ? "" : row["nm_filiale"]
          }";"${
            row["filiali_aggiuntive"] == null ? "" : row["filialiText"]
          }";"${row["username"]}";"${
            row["ultimo_login"] == undefined ? "" : row["ultimo_login"]
          }";"${row["livelloText"]}";"${row["trackingText"]}";"${
            row["inventarioText"]
          }";"${row["chatText"]}";\n`;
        });
        var csvData = new Blob([csv], { type: "text/csv" });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();

        this.itemsPerPage = itemsBuffer;
      });
    },
  },
};
</script>

<style>
.v-card__text .v-input {
  width: 100% !important;
  max-width: 100% !important;
}

.delete {
  word-break: normal;
}
</style>