import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    selectedInventory: null,
    filiale: '',
    cd_filiale: null,
    livello: 0,
    id_utente: null,
    url: 'https://palmariarco.newtonsrl.eu/SORGENTI/',
    filtersObj: {
      codiceFiliale: "",
      codiceInventario: "",
      apertoDa: "",
      chiusoDa: "",
      dates: [],
      stato: "",
    },
    search: ''
  },
  getters: {
    loggedIn(state) {
      if (router.currentRoute.params.jwt != null && router.currentRoute.params.jwt != undefined) {
        state.token = router.currentRoute.params.jwt;
        localStorage.setItem('jwt', router.currentRoute.params.jwt);
      } else if (localStorage.getItem('jwt') != null && state.token == null) {
        state.token = localStorage.getItem('jwt');
      }

      return state.token != null;
    },
    selectedInventory(state) {
      if (localStorage.getItem('selectedInventory') != null && state.selectedInventory == null) {
        state.selectedInventory = JSON.parse(localStorage.getItem('selectedInventory'));
      }

      return state.selectedInventory;
    }
  },
  mutations: {
    updateToken(state, token) {
      state.token = token;
      localStorage.setItem('jwt', token);
    },
    setFiliale(state, filiale) {
      state.filiale = filiale;
    },
    destroyToken(state) {
      state.token = null;
      state.filiale = '';
      state.cd_filiale = null;
      state.livello = 0;
      state.filtersObj = {
        codiceFiliale: "",
        codiceInventario: "",
        apertoDa: "",
        chiusoDa: "",
        dates: [],
        stato: "",
      };
      state.search = '';
      localStorage.clear();
    },
    editInventory(state, inventory) {
      state.selectedInventory = inventory;
      localStorage.setItem('selectedInventory', JSON.stringify(inventory));
    }
  },
  actions: {
    updateToken(context) {
      axios.post(`${context.state.url}updateJWT.php`, {
        jwt: context.state.token
      })
        .then(response => {
          context.commit('updateToken', response.data.jwt);
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.href = "https://mobilearcotracking.newtonsrl.eu/index.php?msg=Token%20scaduto";
          }
          console.log(error)
        })
    },
  },
  modules: {
  }
})
