<template>
  <v-container>
    <v-row>
      <v-col>
        <v-sheet
          min-height="calc(100vh - 88px)"
          rounded="lg"
          class="px-5 pt-5 position-txt"
        >
          <h3>Storico palmari</h3>

          <v-progress-circular
            class="new-inv"
            indeterminate
            size="64"
            v-if="palmariLoaded == false"
          ></v-progress-circular>

          <v-combobox
            class="mr-5"
            v-model="model"
            :items="seriali"
            :search-input.sync="search"
            label="Seleziona o inserisci i seriali"
            multiple
            chips
            deletable-chips
            clearable
            full-width
          />

          <!-- TABLE -->
          <v-data-table
            v-if="serialResults.length != 0"
            :headers="headers"
            :items="serialResults"
            :items-per-page="itemsPerPage"
            :search="search"
            :sort-by="['cd_filiale']"
            multi-sort
            fixed-header
            height="calc(100vh - 291px)"
            no-results-text="Nessun risultato"
            @current-items="getData"
            :footer-props="{
              'items-per-page-options': [15, 25, 50, -1],
              'items-per-page-text': 'Elementi per pagina',
              'items-per-page-all-text': 'Tutti',
            }"
          >
            <template v-slot:[`item.data_rilevamento`]="{ item }">
              {{ formatDate(item.data_rilevamento) }}
            </template>

            <template v-slot:[`item.previsto`]="{ item }">
              <v-avatar
                :color="getColor(item.previsto, item.data_rilevamento)"
                dark
                size="26"
              >
              </v-avatar>
            </template>

            <template
              v-slot:[`footer.prepend`]
              v-if="$store.state.livello == '2'"
            >
              <v-btn
                @click="downloadCSV()"
                text
                class="ml-4 px-2"
                color="grey darken-3"
              >
                Esporta
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <h1
            class="no-results"
            v-if="model.length != 0 && serialResults.length == 0"
          >
            Nessun risultato
          </h1>

          <!-- EXPORT DIALOG -->
          <v-dialog v-model="dialogCSV" max-width="500px">
            <v-card>
              <v-card-title class="headline pt-4">Download CSV</v-card-title>
              <v-card-text>
                <div class="mt-4">
                  <p style="display: inline">File .csv pronto!</p>
                  <a
                    :download="'Utenti-' + dateNow + '.csv'"
                    :href="fileCSV"
                    style="text-decoration: none; display: inline"
                    class="ml-4"
                  >
                    <v-btn @click="dialogCSV = false" color="primary">
                      Download
                      <v-icon right> mdi-download </v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogCSV = false"
                  >Chiudi</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var dayjs = require("dayjs");

export default {
  name: "StoricoPalmari",

  data() {
    return {
      palmariLoaded: false,
      palmari: [],
      seriali: [],
      itemsPerPage: 15,
      model: [],
      search: null,
      serialResults: [],
      dialogCSV: false,
      dateNow: "",
      fileCSV: null,
    };
  },
  mounted() {
    this.getPalmari();
  },
  watch: {
    model: function () {
      if (this.model.length == 0) {
        this.serialResults = [];
      } else {
        this.getSerial();
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Seriale",
          align: "start",
          value: "seriale",
          width: "10%",
        },
        {
          text: "Codice filiale",
          value: "cd_filiale",
          width: "15%",
        },
        {
          text: "Codice inventario",
          value: "cd_inventario",
          width: "15%",
        },
        {
          text: "Utente rilevamento",
          value: "utente_rilevamento",
          width: "15%",
        },
        {
          text: "Data rilevamento",
          value: "data_rilevamento",
          width: "15%",
        },
        {
          text: "Nota",
          value: "nota",
          width: "20%",
        },
        {
          text: "Previsto",
          value: "previsto",
          width: "10%",
        },
      ];
    },
  },
  methods: {
    getPalmari() {
      axios
        .get(
          `${
            this.$store.state.url
          }getPalmari.php?cd_filiale=${localStorage.getItem("cd_filiale")}`
        )
        .then((response) => {
          this.palmari = [...response.data];
          this.palmari.forEach((element) => {
            this.seriali.push(`${element.seriale} - ${element.cd_filiale}`);
          });
          this.palmariLoaded = true;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.palmariLoaded = true;
        });
    },
    getSerial() {
      var seriali = [];
      this.model.forEach((element) => {
        var serial = element.split(" - ")[0];
        seriali.push(serial);
      });
      axios
        .get(`${this.$store.state.url}getStorico.php`, {
          params: {
            seriali: seriali,
          },
        })
        .then((response) => {
          this.serialResults = [...response.data];
          this.serialResults.map((item) => {
            item.data_rilevamento == null ? (item.data_rilevamento = "") : null;
            var year = item.cd_inventario.substr(0, 4);
            var filiale = item.cd_inventario.substr(4, 3);
            var number = item.cd_inventario.substr(7);
            item.cd_inventario = `${year}-${filiale}-${number}`;
            return item;
          });
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    formatDate(date) {
      if (date != "") {
        return dayjs(date).format("DD/MM/YYYY HH:mm");
      }
    },
    getColor(previsto, data) {
      if (data == null || data == undefined || data == "") return "red";
      else if (previsto == "1") return "green";
      else return "orange";
    },
    getData(e) {
      this.currentItems = e;
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;
      this.$nextTick(() => {
        csv += `"Seriale";"Codice filiale";"Codice inventario";"Utente rilevamento";"Data rilevamento";"Nota";"Previsto"\n`;

        this.currentItems.forEach(function (row) {
          var previstoText;
          if (row["data_rilevamento"] == null || row["data_rilevamento"] == undefined || row["data_rilevamento"] == "") {
            previstoText = "Mancanza";
          } else if (row['previsto'] == "1") {
            previstoText = "Rilevato";
          } else {
            previstoText = "Eccedenza";
          }

          csv += `"${row["seriale"]}";"=""${row["cd_filiale"]}""";"${
            row["cd_inventario"]
          }";"${
            row["utente_rilevamento"] == null ? "" : row["utente_rilevamento"]
          }";"${
            row["data_rilevamento"] == null ? "" : row["data_rilevamento"]
          }";"${row["nota"] == null ? "" : row["nota"]}";"${previstoText}";\n`;
        });
        var csvData = new Blob([csv], { type: "text/csv" });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();

        this.itemsPerPage = itemsBuffer;
      });
    },
  },
};
</script>

<style>
.v-input {
  width: 50% !important;
  max-width: 50% !important;
}

div.position-txt {
  min-height: calc(100vh - 128px);
  position: relative;
}

h1.no-results {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
}
.v-select__selections {
  overflow-y: auto;
  flex-wrap: nowrap;
}
.v-chip {
  overflow: initial;
}
</style>