<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat v-if="showBar">
      <v-container class="py-0 fill-height">
        <v-row class="align-center">
          <v-col cols="4">
            <v-img
              src="./assets/img/arco.png"
              alt="arco spedizioni logo"
              max-width="200px"
              class="mt-1"
            ></v-img>
          </v-col>

          <v-col cols="4">
            <h2
              class="secondary--text font-weight-bold text-center text-body-1 text-sm-h5"
            >
              INVENTARIO
            </h2>
          </v-col>

          <v-col cols="4" class="d-flex justify-end">
            <div>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-list rounded>
                  <v-list-item
                    link
                    to="/utenti"
                    v-if="loggedIn && $store.state.livello == '2'"
                  >
                    <v-icon class="mr-2" color="primary"
                      >mdi-account-group</v-icon
                    >
                    <v-list-item-title>Gestione utenti</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    to="/gestione-aree"
                    v-if="loggedIn && $store.state.livello == '2'"
                  >
                    <v-icon class="mr-2" color="primary"
                      >mdi-select-marker</v-icon
                    >
                    <v-list-item-title>Gestione aree</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    to="/import-palmari"
                    v-if="loggedIn && $store.state.livello == '2'"
                  >
                    <v-icon class="mr-2" color="primary"
                      >mdi-cellphone-cog</v-icon
                    >
                    <v-list-item-title>Import palmari</v-list-item-title>
                  </v-list-item>
                  <v-list-item link to="/inventory">
                    <v-icon class="mr-2" color="primary"
                      >mdi-list-status</v-icon
                    >
                    <v-list-item-title v-if="loggedIn"
                      >Inventario</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link to="/storico-palmari">
                    <v-icon class="mr-2" color="primary">mdi-history</v-icon>
                    <v-list-item-title v-if="loggedIn"
                      >Storico palmari</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    link
                    to="/gestione-palmari"
                    v-if="
                      loggedIn &&
                      $store.state.livello == '2' &&
                      $store.state.id_utente == 5
                    "
                  >
                    <v-icon class="mr-2" color="primary">mdi-cellphone</v-icon>
                    <v-list-item-title v-if="loggedIn"
                      >Gestione palmari</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="destroyToken()">
                    <v-icon class="mr-2" color="primary">mdi-logout</v-icon>
                    <v-list-item-title v-if="loggedIn"
                      >Logout</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view />
    </v-main>

    <Snackbar ref="snackbar" />
  </v-app>
</template>

<script>
import router from "./router";
import Snackbar from "./components/Snackbar.vue";

export default {
  name: "App",
  components: {
    Snackbar,
  },

  data() {
    return {
      showBar: this.$route.name == "Error" ? false : true,
    };
  },
  created() {
    this.checkLogin();
  },
  mounted() {
    this.$root.Snackbar = this.$refs.snackbar;
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  methods: {
    checkLogin() {
      if (
        this.$route.params.jwt != null &&
        this.$route.params.jwt != undefined &&
        this.$route.params.jwt != ""
      ) {
        axios.defaults.headers.common["X-Auth-Token"] = this.$route.params.jwt;
        this.checkToken(this.$route.params.jwt)
          .then(() => {
            localStorage.setItem("jwt", this.$route.params.jwt);
            this.$store.state.token = this.$route.params.jwt;
            router.push("/inventory");
          })
          .catch((error) => {
            localStorage.clear();
            window.location.href =
              "https://mobilearcotracking.newtonsrl.eu/index.php";
          });
      } else if (
        this.$store.state.token != null &&
        this.$store.state.token != undefined &&
        this.$store.state.token != ""
      ) {
        axios.defaults.headers.common["X-Auth-Token"] = this.$store.state.token;
        this.checkToken(this.$store.state.token)
          .then(() => {
            localStorage.setItem("jwt", this.$store.state.token);
            if (this.$route.path == "/" || this.$route.path == "/login") {
              router.push("/inventory");
            }
          })
          .catch((error) => {
            localStorage.clear();
            window.location.href =
              "https://mobilearcotracking.newtonsrl.eu/index.php";
          });
      } else {
        localStorage.clear();
        window.location.href =
          "https://mobilearcotracking.newtonsrl.eu/index.php";
      }
    },
    checkToken(token) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.$store.state.url}checkJWT.php`, {
            jwt: token,
          })
          .then((response) => {
            this.$store.state.filiale = response.data["nome_filiale"];
            this.$store.state.cd_filiale = response.data["cd_filiale"];
            localStorage.setItem("filiale", response.data["nome_filiale"]);
            localStorage.setItem("cd_filiale", response.data["cd_filiale"]);
            this.$store.state.livello = response.data["livello"];
            localStorage.setItem("livello", response.data["livello"]);
            this.$store.state.id_utente = response.data["id_utente"];
            localStorage.setItem("id_utente", response.data["id_utente"]);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    destroyToken() {
      this.$store.commit("destroyToken");
      window.location.href =
        "https://mobilearcotracking.newtonsrl.eu/index.php";
    },
    goToTracking() {
      window.location.href =
        "https://mobilearcotracking.newtonsrl.eu/index.php";
    },
  },
  watch: {
    $route() {
      if (this.$route.name == "Error") {
        this.showBar = false;
      } else {
        this.showBar = true;
      }
      if (this.$route.name == "Login") {
        this.checkLogin();
      }

      if (this.$route.name == "Utenti" && this.$store.state.livello != "2") {
        this.destroyToken();
      }

      this.$store.dispatch("updateToken");
    },
  },
};
</script>

<style>
.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: #4eabdd;
}

.v-list .v-list-item--active {
  color: #4eabdd;
}
</style>
