<template>
  <v-container>
    <v-row>
      <v-col class="col-12 col-md-3">
        <v-sheet rounded="lg">
          <!-- LEFT SIDE -->
          <v-list color="secondary" dark rounded>
            <v-btn
              to="/inventory"
              v-if="loggedIn"
              class="mx-2 p-absolute"
              fab
              dark
              small
              color="primary"
            >
              <v-icon dense>mdi-arrow-left </v-icon>
            </v-btn>
            <h3 class="text-center">
              Filiale di {{ selectedInventory["nm_filiale"] }}
            </h3>

            <v-list-item>
              <v-list-item-content class="text-center">
                <v-list-item-title class="mt-3">
                  Codice inventario:
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  selectedInventory["cd_inventario"]
                }}</v-list-item-subtitle>
                <v-list-item-title class="mt-3"> Aperto da: </v-list-item-title>
                <v-list-item-subtitle>{{
                  selectedInventory["utente_apertura"]
                }}</v-list-item-subtitle>
                <v-list-item-title class="mt-3">
                  Data d'apertura:
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  formatDate(selectedInventory["data_apertura"])
                }}</v-list-item-subtitle>
                <v-list-item-title
                  class="mt-3"
                  v-if="selectedInventory['utente_chiusura'] != null"
                >
                  Chiuso da:
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="selectedInventory['utente_chiusura'] != null"
                  >{{
                    selectedInventory["utente_chiusura"]
                  }}</v-list-item-subtitle
                >
                <v-list-item-title
                  class="mt-3"
                  v-if="selectedInventory['data_chiusura_annullamento'] != ''"
                >
                  Data di chiusura:
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="selectedInventory['data_chiusura_annullamento'] != ''"
                  >{{
                    formatDate(selectedInventory["data_chiusura_annullamento"])
                  }}</v-list-item-subtitle
                >
                <v-list-item-title class="mt-3"> Palmari: </v-list-item-title>
                <v-list-item-subtitle>
                  <span>Totale di competenza: {{ totPrevisti }}</span
                  ><br />
                  <span>Totale letture: {{ getLetti }}</span
                  ><br />
                  <span class="green--text"
                    >Previsti: {{ getLetti - quantitaPalmari }}</span
                  ><br />
                  <span class="orange--text" v-if="quantitaPalmari != '0'"
                    >Eccedenze: {{ quantitaPalmari }}</span
                  ><br v-if="quantitaPalmari != '0'" />
                  <span class="red--text" v-if="getMancanti != '0'">
                    Mancanze: {{ getMancanti }}
                  </span>
                </v-list-item-subtitle>

                <v-list-item-title class="mt-3"> Stato: </v-list-item-title>
                <div>
                  <v-chip :color="color">{{ stato }} </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              class="my-2"
              v-if="stato == 'Aperto' && $store.state.livello == '0'"
            ></v-divider>

            <v-list-item
              v-if="stato == 'Aperto' && ($store.state.livello == '0' || $store.state.livello == '1' || $store.state.livello == '2')"
            >
              <v-list-item-content class="text-center">
                <v-list-item-title>
                  <v-btn
                    class="ma-2"
                    outlined
                    color="primary"
                    @click="goToSummary"
                  >
                    Riepilogo inventario
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>

      <!-- RIGHT SIDE -->
      <v-col>
        <v-sheet
          min-height="calc(100vh - 88px)"
          rounded="lg"
          class="pa-5 position-txt"
        >
          <!-- READ BARCODE WINDOW -->
          <template>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Seriale {{ serialInBarcode }}</span>
                </v-card-title>
                <v-card-subtitle class="mt-2 size">Nota</v-card-subtitle>

                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-textarea
                        filled
                        counter
                        label="Inserisci la nota"
                        v-model="nota"
                        clearable
                        @focus="focused = true"
                        @blur="focused = false"
                      ></v-textarea>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeDialog">
                    Chiudi
                  </v-btn>
                  <v-btn
                    color="primary"
                    ref="saveBtn"
                    id="save"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="processBarcode(lastCode)"
                  >
                    Conferma
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <!-- WRONG BARCODE -->
          <v-dialog v-model="alert" max-width="290">
            <v-card>
              <v-card-title class="text-h5 alert">
                {{ message }}
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="red darken-1"
                  text
                  @click="alert = false"
                  ref="closeAlert"
                >
                  Chiudi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div v-if="notaGenerale != ''">
            <h4>Nota generale</h4>
            <pre>{{ notaGenerale }}</pre>
          </div>

          <!-- WHEN NO DATA -->
          <div
            class="new-inv text-center"
            v-if="
              palmari.length == 0 &&
              palmariLoaded == true &&
              $store.state.livello == '0'
            "
          >
            <h1 class="mb-2">Leggi il primo barcode</h1>
          </div>
          <div
            class="new-inv text-center"
            v-if="
              palmari.length == 0 &&
              palmariLoaded == true &&
              $store.state.livello > 0
            "
          >
            <h1 class="mb-2">Non ci sono palmari rilevati</h1>
          </div>

          <v-progress-circular
            class="new-inv"
            indeterminate
            size="64"
            v-if="palmariLoaded == false"
          ></v-progress-circular>

          <!-- TABLE -->
          <v-data-table
            v-if="palmari.length != 0 && palmariLoaded == true"
            :headers="headers"
            :items="palmari"
            :sort-by="['previsto', 'data_rilevamento']"
            :sort-desc="[true, true]"
            :items-per-page="15"
            :search="search"
            multi-sort
            fixed-header
            height="calc(100vh - 257px)"
            no-results-text="Nessun risultato"
            :footer-props="{
              'items-per-page-options': [15, 25, 50, -1],
              'items-per-page-text': 'Elementi per pagina',
              'items-per-page-all-text': 'Tutti',
            }"
          >
            <template v-slot:top>
              <div class="d-flex justify-center align-center">
                <v-text-field
                  v-model="search"
                  label="Cerca"
                  class="mx-4"
                  @focus="focused = true"
                  @blur="focused = false"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                ></v-text-field>
              </div>
            </template>

            <template v-slot:[`item.data_rilevamento`]="{ item }">
              {{ formatDate(item.data_rilevamento) }}
            </template>

            <template v-slot:[`item.previsto`]="{ item }">
              <v-avatar
                :color="getColor(item.previsto, item.data_rilevamento)"
                dark
                size="26"
              >
              </v-avatar>
            </template>
          </v-data-table> </v-sheet></v-col
    ></v-row>
  </v-container>
</template>

<script>
import router from "../router";
var dayjs = require("dayjs");

export default {
  name: "InventoryDetails",

  data() {
    return {
      selectedInventory: null,
      stato: "",
      color: "",
      lastScannedBarCode: "",
      lastCode: "",
      notaGenerale: "",
      palmari: [],
      totPrevisti: null,
      dialog: false,
      alert: false,
      message: "",
      nota: "",
      palmari: [],
      previsti: "",
      search: "",
      serialInBarcode: "",
      focused: false,
      palmariLoaded: false,
      loading: false,
    };
  },
  created: function () {
    if (this.$store.state.selectedInventory == null) {
      this.selectedInventory = this.$store.getters.selectedInventory;
    } else {
      this.selectedInventory = this.$store.state.selectedInventory;
    }

    if (this.selectedInventory["note"] != null) {
      this.notaGenerale = this.selectedInventory["note"];
    }

    switch (this.selectedInventory["stato"]) {
      case "0":
        this.stato = "Aperto";
        this.color = "primary";
        if (this.$store.state.livello == "0") {
          document.onkeypress = this.onGlobalKeyPressed;
        }
        break;
      case "1":
        this.color = "green";
        this.stato = "Chiuso";
        break;
      case "2":
        this.color = "orange";
        this.stato = "Chiuso";
        break;
      case "3":
        this.color = "red";
        this.stato = "Chiuso";
        break;
      default:
        break;
    }
  },
  mounted: function () {
    this.getPalmari();
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    headers() {
      return [
        {
          text: "Seriale",
          align: "start",
          value: "seriale",
          width: "20%",
        },
        {
          text: "Utente rilevamento",
          value: "utente_rilevamento",
          width: "20%",
        },
        {
          text: "Data rilevamento",
          value: "data_rilevamento",
          width: "20%",
        },
        {
          text: "Nota",
          value: "nota",
          width: "30%",
        },
        {
          text: "Previsto",
          value: "previsto",
          width: "10%",
        },
      ];
    },
    quantitaPalmari() {
      return this.palmari.filter((item) => item.previsto == 0).length;
    },
    getLetti() {
      return this.palmari.filter(
        (item) => item.data_rilevamento != null && item.data_rilevamento != ""
      ).length;
    },
    getMancanti() {
      //  Previsti - Verdi 
      return this.totPrevisti - this.palmari.filter((item) => !!item.data_rilevamento && item.previsto == 1).length;
    }
  },
  methods: {
    getPalmari() {
      axios
        .get(
          `${this.$store.state.url}getDettaglioInventari.php?id_inventario=${this.selectedInventory["id"]}`
        )
        .then((response) => {
          this.palmari = [...response.data.palmari];
          this.totPrevisti = response.data.inventario.n_previsti;
          this.palmari.map((item) => (item.previsto = parseInt(item.previsto)));
          this.palmariLoaded = true;
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.alert = true;
          this.palmariLoaded = true;
        });
    },
    onGlobalKeyPressed(e) {
      if (!this.focused) {
        var charCode = typeof e.which == "number" ? e.which : e.keyCode;

        if (charCode != 13) {
          this.lastScannedBarCode += String.fromCharCode(charCode);
        } else {
          this.lastCode = this.lastScannedBarCode.substr(
            this.lastScannedBarCode.length - 31
          );

          try {
            var dateInBarcode = this.lastCode.substr(0, 8);
            this.serialInBarcode = this.lastCode.substr(14, 10);
            var key = this.lastCode.substr(24);
            var currentDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10)
              .replaceAll("-", "");

            if (key == "ARCOSPE") {
              if (dateInBarcode == currentDate) {
                var readSerial = this.palmari.filter(
                  (item) => item.seriale == this.serialInBarcode
                );
                if (readSerial.length != 0) {
                  this.nota = readSerial[0].nota;
                }
                this.dialog = true;
                setTimeout(() => {
                  this.$nextTick(() => {
                    this.$refs.saveBtn.$el.focus();
                  });
                }, 50);
              } else {
                throw "data";
              }
            } else {
              throw "chiave";
            }
          } catch (e) {
            this.lastScannedBarCode = "";
            this.lastCode = "";
            if (e == "data") {
              this.message = "Barcode scaduto!";
            } else {
              this.message = "Barcode non riconosciuto!";
            }
            this.alert = true;
            setTimeout(() => {
              this.$nextTick(() => {
                this.$refs.closeAlert.$el.focus();
              });
            }, 50);
          }
        }
      }
    },
    processBarcode(barcode) {
      this.loading = true;
      axios
        .post(`${this.$store.state.url}insertDettaglioInventari.php`, {
          jwt: this.$store.state.token,
          barcode: barcode,
          id_inventario: this.selectedInventory["id"],
          nota: this.nota,
        })
        .then((response) => {
          this.loading = false;
          this.dialog = false;
          this.getPalmari();
          this.nota = "";
          this.lastScannedBarCode = "";
        })
        .catch((error) => {
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.href =
              "https://mobilearcotracking.newtonsrl.eu/index.php?msg=Token%20scaduto";
          } else {
            this.message = error.response.data.message;
            this.alert = true;
            this.loading = false;
            this.nota = "";
            this.lastScannedBarCode = "";
          }
        });
    },
    closeDialog() {
      this.dialog = false;
      this.nota = "";
      this.lastScannedBarCode = "";
    },
    formatDate(date) {
      if (date != "" && date != null) {
        return dayjs(date).format("DD/MM/YYYY HH:mm");
      }
    },
    getColor(previsto, data) {
      if (data == null || data == undefined) return "red";
      else if (previsto == "1") return "green";
      else return "orange";
    },
    goToSummary() {
      router.push(`/inventory-summary/${this.selectedInventory["id"]}`);
    },
  },
  beforeDestroy: function () {
    document.removeEventListener("keypress", this.onGlobalKeyPressed);
  },
};
</script>

<style>
div.position-txt {
  min-height: 70vh;
  position: relative;
}

div.new-inv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
  width: 50%;
}

.size {
  font-size: 1.2rem !important;
}

.alert {
  word-break: normal;
}

pre {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
</style>