<template>
  <v-container>
    <v-row>
      <v-col>
        <v-sheet min-height="70vh" rounded="lg" class="pa-5">
          <v-btn
            v-if="loggedIn"
            :to="`/inventory-details/${$store.state.selectedInventory['id']}`"
            class="mx-2 p-absolute"
            fab
            dark
            small
            color="primary"
          >
            <v-icon dense>mdi-arrow-left </v-icon>
          </v-btn>

          <!-- INVENTORY INFO -->
          <v-card color="secondary" dark shaped class="mt-5">
            <v-card-title class="text-h5">
              Riepilogo dell'inventario
              {{ $store.state.selectedInventory["cd_inventario"] }}
            </v-card-title>

            <v-card-subtitle
              >Aperto da
              {{ $store.state.selectedInventory["utente_apertura"] }} il
              {{ data }} alle {{ ora }}</v-card-subtitle
            >

            <v-card-text>
              Previsti:
              <v-chip class="ma-2" color="primary">{{ previsti }}</v-chip
              ><br />
              Rilevati:
              <v-chip class="ma-2" color="green">{{
                rilevati - eccedenze
              }}</v-chip>
              <v-chip class="ma-2" color="orange">{{ eccedenze }}</v-chip
              ><br />
              Mancanti:
              <v-chip class="ma-2" color="red">{{
                palmari.length - rilevati
              }}</v-chip
              ><br />
            </v-card-text>
          </v-card>

          <v-divider class="my-5"></v-divider>

          <!-- TABLE -->
          <template>
            <v-data-table
              :headers="headers"
              :items="palmari"
              :sort-by="['previsto']"
              :sort-desc="[true]"
              :items-per-page="50"
              :search="search"
              fixed-header
              height="480px"
              no-results-text="Nessun risultato"
              no-data-text="Nessun dato disponibile"
              :footer-props="{
                'items-per-page-options': [25, 50, -1],
                'items-per-page-text': 'Elementi per pagina',
                'items-per-page-all-text': 'Tutti',
              }"
            >
              <template v-slot:top>
                <div class="d-flex justify-center align-center">
                  <v-text-field
                    v-model="search"
                    label="Cerca"
                    class="mx-4"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                  ></v-text-field>
                </div>
              </template>

              <template v-slot:[`item.data_rilevamento`]="{ item }">
                {{ formatDate(item.data_rilevamento) }}
              </template>

              <template v-slot:[`item.previsto`]="{ item }">
                <v-avatar
                  :color="getColor(item.previsto, item.data_rilevamento)"
                  dark
                  size="26"
                >
                </v-avatar>
              </template>

              <template v-slot:[`item.nota`]="{ item }">
                {{ item.nota }}
                <v-icon
                  v-if="
                    item.nota != null &&
                    item.nota != '' &&
                    $store.state.livello == '0' &&
                    $store.state.selectedInventory['stato'] == '0'
                  "
                  small
                  class="ml-2"
                  @click="editNota(item)"
                >
                  mdi-pencil
                </v-icon>

                <v-icon
                  v-if="
                    (item.nota == null || item.nota == '') &&
                    $store.state.livello == '0' &&
                    $store.state.selectedInventory['stato'] == '0'
                  "
                  small
                  class="ml-2"
                  @click="editNota(item)"
                >
                  mdi-note-plus-outline
                </v-icon>
              </template>
            </v-data-table>
          </template>

          <!-- DIALOG NOTA DEL PALMARE -->
          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title v-if="modifiedItem != null">
                <span class="text-h5"
                  >Seriale {{ modifiedItem["seriale"] }}</span
                >
              </v-card-title>
              <v-card-subtitle class="mt-2 size">Nota</v-card-subtitle>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-textarea
                      counter
                      label="Inserisci la nota"
                      v-model="nota"
                      clearable
                    ></v-textarea>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="dialog = false">
                  Chiudi
                </v-btn>
                <v-btn color="primary" text @click="sendNota"> Conferma </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- DIALOG CHIUSURA -->
          <div
            class="text-center"
            v-if="
              $store.state.selectedInventory['stato'] == '0'
            "
          >
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="dialogClosure"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" color="primary" v-bind="attrs" v-on="on">
                  Chiudi inventario
                </v-btn>
              </template>
              <v-card>
                <v-toolbar color="red" dark>Chiusura</v-toolbar>
                <v-card-title v-if="$store.state.livello == '0' && (eccedenze != 0 || mancanti != 0)">Attenzione</v-card-title>
                <v-card-text v-if="$store.state.livello == '0' && (eccedenze != 0 || mancanti != 0)">
                  L'inventario presenta delle anomalie e può essere chiuso solo da un amministratore o un capo area. Vuoi inviare la
                  richiesta di chiusura?
                </v-card-text>
                <v-card-text v-if="($store.state.livello == '0' && eccedenze == 0 && mancanti == 0) || $store.state.livello == '1' || $store.state.livello == '2'">
                  <div class="text-h5 pa-12">
                    Sei sicuro di voler chiudere l'inventario
                    {{ $store.state.selectedInventory["cd_inventario"] }}?
                  </div>

                  <!-- NOTA GENERALE -->
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Note (facoltativo)"
                    v-model="notaGenerale"
                    clearable
                    auto-grow
                    v-if="($store.state.livello == '0' && eccedenze == 0 && mancanti == 0) || $store.state.livello == '1' || $store.state.livello == '2'"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="secondary" text @click="dialogClosure = false">
                    Torna indietro
                  </v-btn>
                  <v-btn
                    color="green"
                    text
                    @click="closeInventory(1)"
                    :loading="loadBtn"
                    v-if="($store.state.livello == '0' && eccedenze == 0 && mancanti == 0) || $store.state.livello == '1' || $store.state.livello == '2'"
                  >
                    Conferma
                  </v-btn>
                  <v-btn
                    color="green"
                    text
                    @click="closeInventory(0)"
                    :loading="loadBtn"
                    v-if="$store.state.livello == '0' && (eccedenze != 0 || mancanti != 0)"
                  >
                    Invia
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <!-- ALERT -->
          <v-dialog v-model="alert" max-width="290">
            <v-card>
              <v-card-title class="text-h5 alert">
                {{ message }}
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="alert = false">
                  Chiudi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-sheet></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import router from "../router";
var dayjs = require("dayjs");

export default {
  name: "InventorySummary",

  data() {
    return {
      palmari: [],
      search: "",
      data: "",
      ora: "",
      previsti: 0,
      rilevati: 0,
      mancanti: 0,
      eccedenze: 0,
      dialog: false,
      nota: "",
      modifiedItem: null,
      action: "",
      dialogClosure: false,
      alert: false,
      message: "",
      notaGenerale: "",
      loadBtn: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    headers() {
      return [
        {
          text: "Seriale",
          align: "start",
          value: "seriale",
          width: "20%",
        },
        {
          text: "Utente rilevamento",
          value: "utente_rilevamento",
          width: "20%",
        },
        {
          text: "Data rilevamento",
          value: "data_rilevamento",
          width: "20%",
        },
        {
          text: "Nota",
          value: "nota",
          width: "30%",
        },
        {
          text: "Previsto",
          value: "previsto",
          width: "10%",
        },
      ];
    },
  },
  mounted: function () {
    this.getPalmari();
  },
  methods: {
    getPalmari() {
      if (this.$store.state.selectedInventory == null) {
        this.selectedInventory = this.$store.getters.selectedInventory;
      } else {
        this.selectedInventory = this.$store.state.selectedInventory;
      }

      axios
        .get(
          `${this.$store.state.url}getDettaglioInventari.php?id_inventario=${this.$store.state.selectedInventory["id"]}&completo=1`
        )
        .then((response) => {
          this.palmari = [...response.data.palmari];
          this.palmari.map((item) => (item.previsto = parseInt(item.previsto)));
          this.rilevati = this.palmari.filter(
            (item) =>
              item.data_rilevamento != null ||
              item.data_rilevamento != undefined
          ).length;
          this.mancanti = this.palmari.filter(
            (item) =>
              item.data_rilevamento == null ||
              item.data_rilevamento == undefined
          ).length;
          this.eccedenze = this.palmari.filter(
            (item) =>
              item.previsto == 0 &&
              (item.data_rilevamento != null ||
                item.data_rilevamento != undefined)
          ).length;

          this.previsti = this.rilevati - this.eccedenze + this.mancanti;
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.alert = true;
        });

      this.data = dayjs(
        this.$store.state.selectedInventory["data_apertura"]
      ).format("DD/MM/YYYY");
      this.ora = dayjs(
        this.$store.state.selectedInventory["data_apertura"]
      ).format("HH:mm");
    },
    formatDate(date) {
      if (date != "" && date != null) {
        return dayjs(date).format("DD/MM/YYYY HH:mm");
      }
    },
    getColor(previsto, data) {
      if (data == null || data == undefined) return "red";
      else if (previsto == "1") return "green";
      else return "orange";
    },
    editNota(item) {
      this.modifiedItem = item;
      if (item.nota != undefined) {
        this.nota = item.nota;
      } else {
        this.nota = "";
      }
      this.dialog = true;
    },
    sendNota() {
      axios
        .put(`${this.$store.state.url}updateNotaDettaglioInventario.php`, {
          jwt: this.$store.state.token,
          id_inventario: this.$store.state.selectedInventory["id"],
          seriale: this.modifiedItem["seriale"],
          nota: this.nota,
        })
        .then((response) => {
          this.getPalmari();
          this.dialog = false;
          this.nota = "";
        })
        .catch((error) => {
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.href =
              "https://mobilearcotracking.newtonsrl.eu/index.php?msg=Token%20scaduto";
          } else {
            this.message = error.response.data.message;
            this.dialog = false;
            this.nota = "";
            this.alert = true;
          }
        });
    },
    show(data) {
      this.text = data.text || 'missing "message".';
      this.timeout = data.timeout || 2000;
    },
    closeInventory(action) {
      this.loadBtn = true;
      axios
        .put(`${this.$store.state.url}closeInventario.php`, {
          jwt: this.$store.state.token,
          id_inventario: this.$store.state.selectedInventory["id"],
          note: this.notaGenerale,
        })
        .then((response) => {
          this.dialogClosure = false;
          this.loadBtn = false;
            router.push(`/inventory`);
            if (action == 1) {
              this.$root.Snackbar.show({
                text: `L'inventario ${this.$store.state.selectedInventory["cd_inventario"]} è stato chiuso`,
              });
            } else {
              this.$root.Snackbar.show({
              text: `La mail di richiesta è stata inviata`,
            });
            }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.href =
              "https://mobilearcotracking.newtonsrl.eu/index.php?msg=Token%20scaduto";
          } else {
            this.message = error.response.data.message;
            this.dialogClosure = false;
            this.loadBtn = false;
            this.alert = true;
          }
        });
    },
  },
};
</script>

<style>
.size {
  font-size: 1.2rem !important;
}

div.v-toolbar__content {
  justify-content: center;
}

.alert {
  word-break: normal;
}
</style>