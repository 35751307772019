var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[(_vm.showBar)?_c('v-app-bar',{attrs:{"app":"","color":"white","flat":""}},[_c('v-container',{staticClass:"py-0 fill-height"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{staticClass:"mt-1",attrs:{"src":require("./assets/img/arco.png"),"alt":"arco spedizioni logo","max-width":"200px"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('h2',{staticClass:"secondary--text font-weight-bold text-center text-body-1 text-sm-h5"},[_vm._v(" INVENTARIO ")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"4"}},[_c('div',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,false,2340184295)},[_c('v-list',{attrs:{"rounded":""}},[(_vm.loggedIn && _vm.$store.state.livello == '2')?_c('v-list-item',{attrs:{"link":"","to":"/utenti"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-account-group")]),_c('v-list-item-title',[_vm._v("Gestione utenti")])],1):_vm._e(),(_vm.loggedIn && _vm.$store.state.livello == '2')?_c('v-list-item',{attrs:{"link":"","to":"/gestione-aree"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-select-marker")]),_c('v-list-item-title',[_vm._v("Gestione aree")])],1):_vm._e(),(_vm.loggedIn && _vm.$store.state.livello == '2')?_c('v-list-item',{attrs:{"link":"","to":"/import-palmari"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-cellphone-cog")]),_c('v-list-item-title',[_vm._v("Import palmari")])],1):_vm._e(),_c('v-list-item',{attrs:{"link":"","to":"/inventory"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-list-status")]),(_vm.loggedIn)?_c('v-list-item-title',[_vm._v("Inventario")]):_vm._e()],1),_c('v-list-item',{attrs:{"link":"","to":"/storico-palmari"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-history")]),(_vm.loggedIn)?_c('v-list-item-title',[_vm._v("Storico palmari")]):_vm._e()],1),(
                    _vm.loggedIn &&
                    _vm.$store.state.livello == '2' &&
                    _vm.$store.state.id_utente == 5
                  )?_c('v-list-item',{attrs:{"link":"","to":"/gestione-palmari"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-cellphone")]),(_vm.loggedIn)?_c('v-list-item-title',[_vm._v("Gestione palmari")]):_vm._e()],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.destroyToken()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-logout")]),(_vm.loggedIn)?_c('v-list-item-title',[_vm._v("Logout")]):_vm._e()],1)],1)],1)],1)])],1)],1)],1):_vm._e(),_c('v-main',{staticClass:"grey lighten-3"},[_c('router-view')],1),_c('Snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }