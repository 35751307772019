var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"px-5 pt-5 position-txt",attrs:{"min-height":"calc(100vh - 88px)","rounded":"lg"}},[_c('h3',[_vm._v("Storico palmari")]),(_vm.palmariLoaded == false)?_c('v-progress-circular',{staticClass:"new-inv",attrs:{"indeterminate":"","size":"64"}}):_vm._e(),_c('v-combobox',{staticClass:"mr-5",attrs:{"items":_vm.seriali,"search-input":_vm.search,"label":"Seleziona o inserisci i seriali","multiple":"","chips":"","deletable-chips":"","clearable":"","full-width":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),(_vm.serialResults.length != 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.serialResults,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"sort-by":['cd_filiale'],"multi-sort":"","fixed-header":"","height":"calc(100vh - 291px)","no-results-text":"Nessun risultato","footer-props":{
            'items-per-page-options': [15, 25, 50, -1],
            'items-per-page-text': 'Elementi per pagina',
            'items-per-page-all-text': 'Tutti',
          }},on:{"current-items":_vm.getData},scopedSlots:_vm._u([{key:"item.data_rilevamento",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.data_rilevamento))+" ")]}},{key:"item.previsto",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"color":_vm.getColor(item.previsto, item.data_rilevamento),"dark":"","size":"26"}})]}},(_vm.$store.state.livello == '2')?{key:"footer.prepend",fn:function(){return [_c('v-btn',{staticClass:"ml-4 px-2",attrs:{"text":"","color":"grey darken-3"},on:{"click":function($event){return _vm.downloadCSV()}}},[_vm._v(" Esporta "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-download ")])],1)]},proxy:true}:null],null,true)}):_vm._e(),(_vm.model.length != 0 && _vm.serialResults.length == 0)?_c('h1',{staticClass:"no-results"},[_vm._v(" Nessun risultato ")]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogCSV),callback:function ($$v) {_vm.dialogCSV=$$v},expression:"dialogCSV"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline pt-4"},[_vm._v("Download CSV")]),_c('v-card-text',[_c('div',{staticClass:"mt-4"},[_c('p',{staticStyle:{"display":"inline"}},[_vm._v("File .csv pronto!")]),_c('a',{staticClass:"ml-4",staticStyle:{"text-decoration":"none","display":"inline"},attrs:{"download":'Utenti-' + _vm.dateNow + '.csv',"href":_vm.fileCSV}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogCSV = false}}},[_vm._v(" Download "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-download ")])],1)],1)])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogCSV = false}}},[_vm._v("Chiudi")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }