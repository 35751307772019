<template>
  <v-container class="fill-height d-flex align-center justify-center">
    <v-card width="500px" v-if="importStep == 0 && erroriImport.length == 0">
      <v-card-text>
        <div 
          v-if="!file"
          :class="['dropZone-default', 'dropZone', dragging ? 'dropZone-over' : '']" 
          @dragover="dragging = true; preventDefaults($event)"
          @dragenter="dragging = true; preventDefaults($event)" 
          @dragleave="dragging = false; preventDefaults($event)"
          @dragend="preventDefaults($event)" 
          @drop="handleDrop($event)"
          @click="$refs.uploader.click()"
        >
          <div 
            class="fill-height d-flex align-center justify-center flex-column" 
            style="color: #A8A8A8"
          >
             <v-icon style="font-size: 80px">mdi-cloud-upload</v-icon>
             <h2>Carica qui il file Excel.</h2> 
             <h3>Max. 5 MB</h3> 
          </div>
        </div>

        <div 
          v-else
          class="dropZone-default dropZone-uploaded-info"
        >
          <v-icon class="dropZone-cancel-button" @click="removeFile">mdi-close</v-icon>

          <div 
            class="fill-height d-flex align-center justify-center flex-column" 
            style="color: #A8A8A8"
          >
            <v-icon style="font-size: 80px">mdi-file-excel</v-icon>
            <h2 class="mb-1">{{ fileName }}</h2>
          </div>
        </div>

        <p style="text-align: center" class="pa-0 ma-0" v-if="!file">Scarica <a href="https://palmariarco.newtonsrl.eu/templates/Template_import_palmari.xls" target="_blank">qui</a> il template per l'importazione</p>

        <input
          ref="uploader"
          class="d-none"
          type="file"
          @change="fileSelectedFunc"
          accept=".xlsx, .xls"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn 
          color="primary" 
          depressed 
          style="width:100%"
          :disabled="!file || loading"
          :loading="loading"
          @click="saveFile(0)"
        >
          Carica
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card width="100%" v-if="importStep == 1 && erroriImport.length == 0">
      <v-card-title class="pb-0">Modifiche da apportare</v-card-title>
      <v-card-text class="pb-0">
        <v-data-table
          :headers="headers"
          :items="modifiche"
          sort-by="seriale"
          :search="search"
          no-results-text="Nessun risultato"
          no-data-text="Nessuna modifica da apportare"
          fixed-header
          height="calc(100vh - 373px)"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, -1],
            'items-per-page-text': 'Elementi per pagina',
            'items-per-page-all-text': 'Tutti',
          }"
          class="mt-5"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Cerca"
              prepend-inner-icon="mdi-magnify"
              clearable
            ></v-text-field>
          </template>

          <template v-slot:[`item.operazione`]="{ item }">
            <v-chip :color="item.colore">
              {{ item.operazione }}
            </v-chip>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="secondary" 
          @click="removeFile"
        >
          Annulla
        </v-btn>
        <v-btn 
          v-if="modifiche.length > 0"
          color="primary" 
          @click="saveFile(1)"
          :loading="loading"
          :disabled="loading"
        >
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card width="500px" v-if="importStep == 2 && erroriImport.length == 0">
      <v-card-text>
        <div class="dropZone-default">
          <div 
            class="fill-height d-flex align-center justify-center flex-column" 
            style="color: #A8A8A8"
          >
            <v-icon class="mb-4" style="font-size: 80px" color="green">mdi-check-circle</v-icon>
            <h2 class="mb-1">File importato.</h2>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          color="primary" 
          depressed 
          style="width:100%"
          @click="toInventory()"
        >
          Torna agli Inventari
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card width="500px" v-if="erroriImport.length > 0">
      <v-card-text>
        <div class="dropZone-default">
          <div 
            class="fill-height d-flex align-center justify-center flex-column" 
            style="color: #A8A8A8"
          >
            <v-icon class="mb-4" style="font-size: 80px" color="red">mdi-close-circle</v-icon>
            <h2 v-if="importStep > 0" class="mb-2">File non importato:</h2>
            <h2 v-else class="mb-2">Impossibile importare il file:</h2>
            <h3 v-for="errore in erroriImport" class="mb-1" style="text-align: center;">{{ errore }}</h3>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          color="primary" 
          depressed 
          style="width:100%"
          @click="removeFile"
        >
          Carica nuovo file
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import router from "../router";

export default {
  name: "ImportPalmari",

  data() {
    return {
      importStep: 0,
      palmariLoaded: false,
      file: null,
      fileName: "",
      dragging: false,
      loading: false,
      erroriImport: [],
      search: "",
      modifiche: [],
      headers: [
        {
          text: "Seriale",
          align: "start",
          value: "seriale",
        },
        {
          text: "Operazione",
          value: "operazione",
        },
        {
          text: "Modifica",
          value: "modifica"
        }
      ]
    }
  },
  methods: {
    handleDrop(e) {
      e.preventDefault();

      const files = e.dataTransfer.files;

      if (files[0]) {
        this.storeFile(files[0])
      }
    },
    fileSelectedFunc(e) {
      const file = e.target.files[0];

      if (file) {
        this.storeFile(file)
      }
    },
    storeFile(file) {
      this.dragging = false;

      const whitelist_formati = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel"
      ]
      
      if (!whitelist_formati.includes(file.type)) {
        this.erroriImport = ["L'estensione del file non è supportata. Usare solo documenti .xlsx o .xls"]
        return;
      }

      if (file.size > 5000000) {
        this.erroriImport = [`Il file è troppo grande. Scegliere un documento più piccolo di 5 MB`]
        return;
      }
      
      const fr = new FileReader();

      fr.readAsDataURL(file);

      fr.addEventListener("load", (f) => {
        this.file = fr.result;
        this.fileName = file.name;
      });
    },
    saveFile(modifica) {
      this.loading = true

      axios
        .post(`${this.$store.state.url}importPalmari.php`, {
          allegato: this.file,
          modifica: modifica
        })
        .then((response) => {
          if (modifica == 0) {
            this.importStep = 1
            
            this.modifiche = [
              ...response.data.aggiungere.map(el => {return {seriale: el, operazione: "Da aggiungere", modifica: "", colore: "success"}}),
              ...response.data.modificare.map(el => {return {seriale: el["seriale"], operazione: "Da modificare", modifica: `${el["cd_filiale_db"]} -> ${el["cd_filiale"]}`, colore: "warning"}}),
              ...response.data.eliminare.map(el => {return {seriale: el, operazione: "Da eliminare", modifica: "", colore: "error"}})
            ]
          }
          else {
            this.importStep = 2
          }
          this.erroriImport = response.data.errors ? response.data.errors : []
        })
        .catch((error) => {
          this.erroriImport = error.response.data.message ? [error.response.data.message] : [`Errore imprevisto, riprovare più tardi o contattare l'assistenza.`]
        })
        .finally(() => {
          this.loading = false
        });
    },
    removeFile() {
      this.importStep = 0;
      this.file = null;
      this.fileName = "";
      this.dragging = false;
      this.erroriImport = [];
      this.modifiche = [];
    },
    toInventory() {
      router.push("/inventory");
    },
    // Funzione per evitare i comportamenti di default del browser (es. aprire il file in una nuova tab)
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
</script>

<style>
.dropZone-default {
  width: 100%;
  height: 300px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
}

.dropZone {
  cursor: pointer;
  border: 2px dashed rgb(168, 168, 168);
  border-radius: 4px;
}

.dropZone:hover {
  border: 2px dashed #4eabdd;
}

.dropZone-title {
  color: #787878;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #eee;
  opacity: 0.8;
}

.dropZone-cancel-button {
  position: absolute !important;
  right: 2px;
  top: 2px;
}

.dropZone-uploaded-info {
  position: relative;
  border: 2px solid rgb(168, 168, 168);
  border-radius: 4px;
}
</style>