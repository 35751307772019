<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="secondary"
    bottom
    shaped
    elevation="24"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
        Chiudi
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      snackbar: false,
      text: "",
      timeout: 3000,
    };
  },
  methods: {
    show(data) {
      this.text = data.text;
      this.timeout = data.timeout || 3000;
      this.snackbar = true;
    },
  },
};
</script>

<style>
</style>