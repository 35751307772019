<template>
  <div class="position-txt">
    <!-- NEW INVENTORY -->
    <div class="d-flex justify-space-between">
      <h3 class="mb-2">{{ title }}</h3>
      <div class="d-flex align-center">
        <InventoryForm
          v-if="
            inventari.length != 0 &&
            inventariLoaded == true &&
            ($store.state.livello == '0' || $store.state.livello == '3')
          "
        />
      </div>
    </div>

    <v-progress-circular
      class="new-inv"
      indeterminate
      size="64"
      v-if="inventariLoaded == false"
    ></v-progress-circular>

    <div
      class="new-inv text-center"
      v-if="
        inventari.length == 0 &&
        inventariLoaded == true &&
        ($store.state.livello == '0' || $store.state.livello == '3')
      "
    >
      <h1 class="mb-2">Inserisci un nuovo inventario</h1>
      <InventoryForm />
    </div>
    <div
      class="new-inv text-center"
      v-if="
        inventari.length == 0 &&
        inventariLoaded == true &&
        $store.state.livello > 0 &&
        $store.state.livello != '3'
      "
    >
      <h1 class="mb-2">Nessun inventario disponibile</h1>
    </div>

    <!-- ALERT -->
    <v-dialog v-model="alert" max-width="290">
      <v-card>
        <v-card-title class="text-h5 alert">
          {{ message }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="alert = false">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EXPORT DIALOG -->
    <v-dialog v-model="dialogCSV" max-width="500px">
      <v-card>
        <v-card-title class="headline pt-4">Download CSV</v-card-title>
        <v-card-text>
          <div class="mt-4">
            <p style="display: inline">File .csv pronto!</p>
            <a
              :download="'Inventari-' + dateNow + '.csv'"
              :href="fileCSV"
              style="text-decoration: none; display: inline"
              class="ml-4"
            >
              <v-btn @click="dialogCSV = false" color="primary">
                Download
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </a>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogCSV = false">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- LEGENDA -->
    <v-container
      class="mb-5"
      v-if="inventari.length != 0 && inventariLoaded == true"
    >
      <v-row>
        <v-col class="col-12 col-md-4">
          <div class="my-3 d-flex align-center">
            <v-avatar color="primary" dark size="26" class="mr-3"></v-avatar>
            <span>Aperto</span>
          </div>
          <div class="d-flex align-center">
            <v-avatar color="green" dark size="26" class="mr-3"></v-avatar>
            <span>Chiuso senza anomalie e senza annotazioni</span>
          </div>
        </v-col>
        <v-col class="col-12 col-md-8">
          <div class="my-3 d-flex align-center">
            <v-avatar color="orange" dark size="26" class="mr-3"></v-avatar>
            <span>Chiuso con annotazioni</span>
          </div>
          <div class="d-flex align-center">
            <v-avatar color="red" dark size="26" class="mr-3"></v-avatar>
            <span>Chiuso con anomalie</span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- TABLE -->
    <v-data-table
      v-if="inventari.length != 0 && inventariLoaded == true"
      :headers="headers"
      :items="inventari"
      :sort-by="['data_apertura']"
      :sort-desc="[true]"
      :items-per-page="itemsPerPage"
      :search="search"
      :custom-filter="filterAll"
      multi-sort
      no-results-text="Nessun risultato"
      fixed-header
      height="calc(100vh - 418px)"
      :footer-props="{
        'items-per-page-options': [15, 25, 50, -1],
        'items-per-page-text': 'Elementi per pagina',
        'items-per-page-all-text': 'Tutti',
      }"
      @click:row="editInventory"
      class="row-pointer"
      @current-items="getData"
    >
      <template v-slot:top>
        <div class="d-flex justify-center align-center">
          <v-text-field
            v-model="search"
            label="Cerca"
            class="mx-4"
            prepend-inner-icon="mdi-magnify"
            clearable
          ></v-text-field>

          <InventoryFilter @filter-inventory="filter" />
        </div>
      </template>

      <template v-slot:[`item.data_apertura`]="{ item }">
        {{ formatDate(item.data_apertura) }}
      </template>

      <template v-slot:[`item.data_chiusura_annullamento`]="{ item }">
        {{ formatDate(item.data_chiusura_annullamento) }}
      </template>

      <template v-slot:[`item.stato`]="{ item }">
        <v-avatar
          :color="getColor(item)"
          dark
          size="26"
          class="mr-3"
        ></v-avatar>
      </template>

      <template v-slot:[`item.dettaglio`]="{ item }">
        <v-icon size="32px" class="mr-2" @click="editInventory(item)">
          mdi-chevron-right
        </v-icon>
      </template>

      <template v-slot:[`footer.prepend`] v-if="$store.state.livello == '2'">
        <v-btn
          @click="downloadCSV()"
          text
          class="ml-4 px-2"
          color="grey darken-3"
        >
          Esporta
          <v-icon right> mdi-download </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>


<script>
import InventoryForm from "./InventoryForm.vue";
import InventoryFilter from "./InventoryFilter.vue";
import router from "../router";
var dayjs = require("dayjs");

export default {
  name: "InventoryData",

  components: {
    InventoryForm,
    InventoryFilter,
  },

  data() {
    return {
      codiceFiliale: localStorage.getItem("cd_filiale"),
      nomeFiliale: localStorage.getItem("filiale"),
      title: "",
      inventariLoaded: false,
      search: "",
      id: "",
      utente_apertura: "",
      inventari: [],
      itemsPerPage: 15,
      filterParameters: {},
      alert: false,
      message: "",
      currentItems: [],
      dateNow: "",
      fileCSV: null,
      dialogCSV: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Codice filiale",
          align: "start",
          value: "cd_filiale",
          filter: (value) => {
            if (!this.filterParameters["codiceFiliale"]) return true;
            return value
              .toLowerCase()
              .includes(this.filterParameters["codiceFiliale"].toLowerCase());
          },
        },
        {
          text: "Codice inventario",
          value: "cd_inventario",
          filter: (value) => {
            if (!this.filterParameters["codiceInventario"]) return true;
            return value
              .toLowerCase()
              .includes(
                this.filterParameters["codiceInventario"].toLowerCase()
              );
          },
        },
        {
          text: "Aperto da",
          value: "utente_apertura",
          filter: (value) => {
            if (!this.filterParameters["apertoDa"]) return true;
            return value
              .toLowerCase()
              .includes(this.filterParameters["apertoDa"].toLowerCase());
          },
        },
        {
          text: "Data apertura",
          value: "data_apertura",
          filter: (value, search, item) => {
            return this.checkData(item);
          },
        },
        {
          text: "Chiuso da",
          value: "utente_chiusura",
          filter: (value) => {
            if (!this.filterParameters["chiusoDa"]) return true;
            return value
              .toLowerCase()
              .includes(this.filterParameters["chiusoDa"].toLowerCase());
          },
        },
        {
          text: "Data chiusura",
          value: "data_chiusura_annullamento",
          filter: (value, search, item) => {
            return this.checkData(item);
          },
        },
        {
          text: "Stato",
          value: "stato",
          filter: (value) => {
            if (!this.filterParameters["stato"]) return true;
            return this.filterParameters["stato"].includes(value);
          },
        },
        { text: "", value: "dettaglio", sortable: false },
      ];
    },
  },
  mounted: function () {
    if (localStorage.getItem("livello") == "2") {
      this.title = "Admin";
    } else if (localStorage.getItem("livello") == "1") {
      this.title = `Area di ${this.nomeFiliale}`;
    } else {
      this.title = `Filiale di ${this.nomeFiliale}`;
    }

    axios
      .get(`${this.$store.state.url}getInventari.php`)
      .then((response) => {
        this.inventari = [...response.data];
        this.inventari.map((item) => {
          item.data_chiusura_annullamento == null
            ? (item.data_chiusura_annullamento = "")
            : null;
          var year = item.cd_inventario.substr(0, 4);
          var filiale = item.cd_inventario.substr(4, 3);
          var number = item.cd_inventario.substr(7);
          item.cd_inventario = `${year}-${filiale}-${number}`;
          return item;
        });
        this.inventariLoaded = true;
        this.search = this.$store.state.search;
        this.filterParameters = this.$store.state.filtersObj;
      })
      .catch((error) => {
        this.message = error.response.data.message;
        this.alert = true;
        this.inventariLoaded = true;
      }, 5000);
  },
  watch: {
    search: function () {
      this.$store.state.search = this.search;
    },
  },
  methods: {
    getColor(item) {
      if (item.stato == 0) {
        return "primary";
      } else if (item.stato == 1) {
        return "green";
      } else if (item.stato == 2) {
        return "orange";
      } else {
        return "red";
      }
    },
    formatDate(date) {
      if (date != "") {
        return dayjs(date).format("DD/MM/YYYY HH:mm");
      }
    },
    filter(filtersObj) {
      this.filterParameters = filtersObj;
      this.$store.state.filtersObj = filtersObj;
    },
    editInventory(inventory) {
      this.$store.commit("editInventory", inventory);
      router.push(`/inventory-details/${inventory.id}`);
    },
    getData(e) {
      this.currentItems = e;
    },
    filterAll(valore, search, item) {
      var results = [];
      var columns = [];

      this.headers.forEach((element) => {
        columns.push(element.value);
      });

      Object.entries(item).forEach(([key, value]) => {
        if (key == "data_apertura" || key == "data_chiusura_annullamento") {
          value = this.formatDate(value);
        }
        if (
          value != undefined &&
          value != null &&
          value != "" &&
          columns.includes(key) &&
          search != null &&
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
        ) {
          results.push("true");
        }
      });
      return results.includes("true");
    },
    checkData(item) {
      if (
        !this.filterParameters["dates"] ||
        this.filterParameters["dates"].length == 0
      )
        return true;
      var date1 = this.filterParameters["dates"][0].split("/");
      var date2 = this.filterParameters["dates"][1].split("/");
      var apertura = dayjs(item["data_apertura"]).format("DD/MM/YYYY");
      var chiusura = dayjs(item["data_chiusura_annullamento"]).format(
        "DD/MM/YYYY"
      );

      var checkDateApertura = apertura.split("/");
      var checkDateChiusura = chiusura.split("/");

      var from = new Date(date1[2], parseInt(date1[1]) - 1, date1[0]); // -1 because months are from 0 to 11
      var to = new Date(date2[2], parseInt(date2[1]) - 1, date2[0]);
      var check1 = new Date(
        checkDateApertura[2],
        parseInt(checkDateApertura[1]) - 1,
        checkDateApertura[0]
      );
      var check2 = new Date(
        checkDateChiusura[2],
        parseInt(checkDateChiusura[1]) - 1,
        checkDateChiusura[0]
      );

      if (
        (check1 >= from && check1 <= to) ||
        (check2 >= from && check2 <= to)
      ) {
        return true;
      }
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;

      this.$nextTick(() => {
        csv += `"Codice filiale";"Codice inventario";"Aperto da";"Data apertura";"Chiuso da";"Data chiusura";"Stato"\n`;

        this.currentItems.forEach(function (row) {
          var statusText;
          if (row["stato"] == 0) {
            statusText = "Aperto";
          } else if (row["stato"] == 1) {
            statusText = "Chiuso senza anomalie e senza annotazioni";
          } else if (row["stato"] == 2) {
            statusText = "Chiuso con annotazioni";
          } else {
            statusText = "Chiuso con anomalie";
          }

          csv += `"${row["cd_filiale"]}";"${row["cd_inventario"]}";"${
            row["utente_apertura"]
          }";"${row["data_apertura"]}";"${
            row["utente_chiusura"] == null ? "" : row["utente_chiusura"]
          }";"${row["data_chiusura_annullamento"]}";"${statusText}";\n`;
        });
        var csvData = new Blob([csv], { type: "text/csv" });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();

        this.itemsPerPage = itemsBuffer;
      });
    },
  },
};
</script>

<style scoped>
.v-input.mx-4 {
  width: 50%;
  max-width: 50% !important;
}

div.position-txt {
  min-height: calc(100vh - 128px);
  position: relative;
}

div.new-inv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
  width: 50%;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>