<template>
  <v-container>
    <v-row>
      <v-col>
        <v-sheet
          class="pa-5"
          min-height="70vh"
          rounded="lg"
        >
          <InventoryData />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import InventoryData from '../components/InventoryData.vue'

  export default {
    name: 'Inventory',

    components: {
      InventoryData,
    },
  }
</script>

<style>
</style>