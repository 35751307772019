<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab small color="secondary" v-bind="attrs" v-on="on">
          <v-icon color="white"> mdi-plus </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 primary lighten-2">
          Nuovo inventario
        </v-card-title>

        <v-card-text class="mt-2">
          Sei sicuro di voler aprire un nuovo inventario?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Chiudi </v-btn>
          <v-btn color="primary" text @click="goToDetails()"> Conferma </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alert" max-width="350">
      <v-card>
        <v-card-title class="text-h5 alert">
          {{ message }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="alert = false">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import router from "../router";

export default {
  name: "InventoryForm",

  data() {
    return {
      dialog: false,
      alert: false,
      message: "",
    };
  },
  methods: {
    goToDetails() {
      axios
        .post(`${this.$store.state.url}insertInventari.php`, {
          jwt: this.$store.state.token,
        })
        .then((response) => {
          var year = response.data.cd_inventario.substr(0, 4);
          var filiale = response.data.cd_inventario.substr(4, 3);
          var number = response.data.cd_inventario.substr(7);
          response.data.cd_inventario = `${year}-${filiale}-${number}`;
          this.$store.commit("editInventory", response.data);

          router.push(`/inventory-details/${response.data.id}`);
          this.$root.Snackbar.show({
            text: `L'inventario ${response.data.cd_inventario} è stato aperto con successo.`,
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            localStorage.clear();
            window.location.href =
              "https://mobilearcotracking.newtonsrl.eu/index.php?msg=Token%20scaduto";
          } else {
            this.dialog = false;
            this.alert = true;
            this.message = error.response.data.message;
          }
        });
    },
  },
};
</script>

<style>
.alert {
  word-break: normal;
}
</style>