<template>
  <v-container>
    <v-row>
      <v-col>
        <v-sheet
          min-height="calc(100vh - 88px)"
          rounded="lg"
          class="px-5 pt-5 position-txt"
        >
          <h3>Gestione aree</h3>

          <v-data-table
            ref="dataTableAree"
            :headers="headers"
            :items="aree"
            sort-by="codice"
            :search="search"
            no-results-text="Nessun risultato"
            fixed-header
            height="calc(100vh - 280px)"
            :footer-props="{
              'items-per-page-options': [15, 25, 50, -1],
              'items-per-page-text': 'Elementi per pagina',
              'items-per-page-all-text': 'Tutti',
            }"
            @update:page="scrollTable($refs.dataTableAree)"
            class="mt-5"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  v-model="search"
                  label="Cerca"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                ></v-text-field>

                <v-spacer></v-spacer>

                <v-btn fab small color="secondary" @click="addAreaDialog()">
                  <v-icon color="white"> mdi-plus </v-icon>
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:[`item.n_filiali`]="{ item }">
              <v-tooltip bottom v-if="item.n_filiali > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    color="rgba(0, 0, 0, 0.12)" 
                  >                  
                    {{item.n_filiali}}
                  </v-chip>
                </template>
                <!-- Filiale1 (001), Filiale2 (002), ...-->
                <span>{{filiali.filter(obj => obj.cod_id == item.id_filiale).map((el) => `${el.nm_filiale} (${el.codice})`).join(", ")}}</span>
              </v-tooltip>

              <!-- Non mostro il tootlip se non ci sono filiali associate-->
              <v-chip 
                v-else
              >
                {{item.n_filiali}}
              </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editAreaDialog(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteAreaDialog(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- DIALOG EDIT/CREATE -->
    <v-dialog v-model="dialog_edit_add" persistent max-width="900px">
      <v-card>
        <v-card-title>
          {{
            current_item.id_filiale == null ? "Aggiungi Area" : "Modifica Area"
          }}
        </v-card-title>
        <v-card-text class="py-0">
          <v-container fluid>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Codice"
                    :rules="[codiceExists]"
                    v-model="current_item.codice"
                    counter="3"
                    maxlength="3"
                    @keyup="
                      current_item.codice = current_item.codice.toUpperCase()
                    "
                    :readonly="current_item.id_filiale != null"
                    :disabled="current_item.id_filiale != null"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Nome"
                    :rules="nomeRule"
                    v-model="current_item.nm_filiale"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <!-- TABELLA FILIALI -->
              <v-data-table
                ref="dataTableFiliali"
                v-model="current_item.filiali"
                :headers="headers_filiali"
                :items="filiali"
                :search="searchFiliali"
                height="calc(90vh - 333px)"
                style="min-width: 100%"
                fixed-header
                sort-by="codice"
                item-key="id_filiale"
                show-select
                :footer-props="{
                  'items-per-page-options': [15, 25, 50, -1],
                  'items-per-page-text': 'Elementi per pagina',
                  'items-per-page-all-text': 'Tutti',
                }"
                @update:page="scrollTable($refs.dataTableFiliali)"
                @item-selected="checkWarning()"
              >
                <!-- $vuetify.goTo($ref.dataTable,{offset: 50,container: '.v-data-table__wrapper'}) -->
                <template v-slot:[`item.cod_id`]="{ item }">
                  {{ getNomeArea(item) }}
                </template>

                <template v-slot:[`header.data-table-select`]="{}"> </template>

                <template v-slot:top>
                  <v-text-field
                    class="px-3"
                    v-model="searchFiliali"
                    label="Cerca Filiale"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    max-width="50% !important"
                  ></v-text-field>
                </template>

                <template
                  v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
                >
                  <v-simple-checkbox
                    color="primary"
                    :value="isSelected"
                    :ripple="false"
                    @input="select($event)"
                  ></v-simple-checkbox>
                  <!-- 
                      :readonly="item.cod_id != 0 && item.cod_id != current_item.id_filiale"
                      :disabled="item.cod_id != 0 && item.cod_id != current_item.id_filiale"
                    -->
                </template>
              </v-data-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <span class="warning--text body-2" v-if="warning_span">
            ATTENZIONE: le filiali selezionate con già un'area associata verranno automaticamente spostate nell'area in {{current_item.id_filiale == null ? "creazione" : "modifica"}}! 
          </span>
          <v-spacer />
          <v-btn color="secondary darken-1" text @click="closeDialog()">
            Chiudi
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="save()"
            :disabled="!valid || btn_loader"
            :loading="btn_loader"
          >
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialog_delete" max-width="600px" persistent>
      <v-card>
        <v-card-title class="text-h5 justify-center text-center delete"
          >Sei sicuro di voler cancellare l'area "{{ current_item.nm_filiale }}"?
          <h6>Tutti i profili Capo Area associati verranno ELIMINATI.</h6>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" text @click="closeDialog()"
            >Annulla</v-btn
          >
          <v-btn 
            color="primary" 
            text 
            @click="deleteArea()"
            :disabled="btn_loader"
            :loading="btn_loader"
            >Conferma</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ALERT -->
    <v-dialog v-model="alert" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 alert">
          {{ message }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="alert = false">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "GestioneAree",

  data() {
    return {
      aree: [],
      codici_filiali_aree: [],
      filiali: [],
      current_item: {
        id_filiale: null,
        codice: "",
        nm_filiale: "",
        filiali: [],
      },
      template_item: {
        id_filiale: null,
        codice: "",
        nm_filiale: "",
        filiali: [],
      },
      dialog_edit_add: false,
      dialog_delete: false,
      warning_span: false,
      search: "",
      searchFiliali: "",
      nomeRule: [(v) => !!v || "Campo obbligatorio"],
      valid: true,
      alert: false,
      message: "",
      btn_loader: false
    };
  },
  mounted() {
    this.getAreeFiliali();
  },
  computed: {
    headers() {
      return [
        {
          text: "Codice",
          align: "start",
          value: "codice",
        },
        {
          text: "Nome",
          value: "nm_filiale",
        },
        {
          text: "Numero Filiali",
          value: "n_filiali"
        },
        {
          text: "Azioni",
          value: "actions",
          sortable: false,
          width: "10%",
        },
      ];
    },
    headers_filiali() {
      return [
        {
          text: "Codice",
          align: "start",
          value: "codice",
        },
        {
          text: "Nome",
          value: "nm_filiale",
        },
        {
          text: "Area",
          value: "cod_id",
        },
      ];
    },
  },
  methods: {
    getAreeFiliali() {
      axios
        .get(`${this.$store.state.url}getFiliali.php`)
        .then((response) => {
          this.filiali = [
            ...response.data.filter((user) => user.tipologia == 0),
          ];
          this.aree = response.data.filter((user) => user.tipologia == 1);
          this.aree.map((el) => {el.n_filiali = this.filiali.filter(obj => obj.cod_id == el.id_filiale).length})
          this.codici_filiali_aree = response.data.map((obj) => obj.codice);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNomeArea(item) {
      var area = this.aree.find((obj) => obj.id_filiale == item.cod_id);
      return area ? area.nm_filiale : "";
    },
    addAreaDialog() {
      this.current_item = Object.assign({}, this.template_item);
      this.$nextTick(() => {
        this.dialog_edit_add = true;
      });
    },
    editAreaDialog(item) {
      this.current_item = Object.assign({}, item);
      this.current_item.filiali = this.filiali.filter(
        (obj) => obj.cod_id == item.id_filiale
      );
      this.$nextTick(() => {
        this.dialog_edit_add = true;
      });
    },
    deleteAreaDialog(item) {
      this.current_item = Object.assign({}, item);
      this.dialog_delete = true;
    },
    closeDialog() {
      if(this.$refs.form){
        this.$refs.form.reset();
      }
      this.searchFiliali = "";
      this.dialog_edit_add = false;
      this.dialog_delete = false;
      this.current_item = Object.assign({}, this.template_item);
    },
    save() {
      this.btn_loader = true
      var item = Object.assign({}, this.current_item);
      item.filiali = item.filiali.map((obj) => obj.codice);

      if (item.id_filiale == null) {
        axios
          .post(`${this.$store.state.url}insertArea.php`, item)
          .then((response) => {
            this.$root.Snackbar.show({
              text: `L'area "${item.nm_filiale}" è stata aggiunta`,
            });
            this.closeDialog();
            this.getAreeFiliali();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.btn_loader = false
          });
      } else {
        axios
          .put(`${this.$store.state.url}updateArea.php`, item)
          .then((response) => {
            this.$root.Snackbar.show({
              text: `L'area "${item.nm_filiale}" è stata modificata`,
            });
            this.closeDialog();
            this.getAreeFiliali();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.btn_loader = false
          });
      }
    },
    deleteArea() {
      this.btn_loader = true
      var item = Object.assign({}, this.current_item);

      axios
        .delete(`${this.$store.state.url}deleteArea.php`, {
          data: {
            id_filiale: item.id_filiale,
            codice: item.codice,
          },
        })
        .then((response) => {
          this.$root.Snackbar.show({
            text: `L'area "${item.nm_filiale}" è stata cancellata`,
          });
          this.closeDialog();
          this.getAreeFiliali();
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.alert = true;
        })
        .finally(() => {
          this.btn_loader = false
        });
    },
    scrollTable(el) {
      el.$el.getElementsByClassName("v-data-table__wrapper")[0].scrollTop = 0;
    },
    codiceExists(codice) {
      if (!codice) {
        return "Campo obbligatorio";
      }
      if (codice.length < 3) {
        return "Il codice dev'essere formato da 3 caratteri";
      }
      if (this.current_item.id_filiale) {
        if (
          this.codici_filiali_aree.includes(codice.toUpperCase()) &&
          codice.toUpperCase() !=
            this.aree.filter(
              (obj) => obj.id_filiale == this.current_item.id_filiale
            )[0].codice
        ) {
          return "Codice già esistente";
        }
      }
      return true;
    },
    checkWarning() {
      this.$nextTick(() => {
        this.warning_span = this.current_item.filiali.filter(obj => obj.cod_id != this.current_item.id_filiale && obj.cod_id != 0).length > 0;
      })
    }
  },
};
</script>